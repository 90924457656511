import './SignInView.css';
import { useDispatch } from 'react-redux';
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { fetchGetData, fetchRequest, handleDeleteToken, handleInitApple, handleStartLambda, logIssue, validEmail, validatePassword } from '../../helpers/Utils';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import PasswordInput from '../../components/PasswordInput/PasswordInput';
import arrowLeft from '../../assets/arrowLeft.svg';
import singInBtn from '../../assets/singInBtn.svg';
import TextInput from '../../components/TextInput/TextInput';
import ModalWindow from '../../components/ModalWindow/ModalWindow';
import sportMan4 from '../../assets/sportMan4.png';
import logoWhite from '../../assets/logoWhite.svg';
import { pageMeta } from '../../pageMeta';
import PageMeta from '../../components/PageMeta/PageMeta';
import { setShowMessageObj, setUser } from '../../store/homeSlice';
import { setCoach } from '../../store/userSlice';
import PreloaderCustom from '../../components/PreloaderCustom/PreloaderCustom';
import { useSelector } from 'react-redux';
import { translations } from '../../localization';
import SelectLanguage from '../../components/SelectLanguage/SelectLanguage';
import { WARNING } from '../../helpers/Config';
import { Box } from '@mui/material';

function SignInView() {
  const [searchParams, setSearchParams] = useSearchParams();
  const newSearchParams = new URLSearchParams(searchParams);
  const is_appSearchParams = newSearchParams.get('is_app');
  const language = useSelector((state) => state.homeSlice.language);
  const [isApp, setIsApp] = useState(is_appSearchParams && is_appSearchParams !== 'undefined' ? true : false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isErrorEmail, setIsErrorEmail] = useState(false);
  const [isErrorRestoreEmail, setIsErrorRestoreEmail] = useState(false);
  const [isErrorPassword, setIsErrorPassword] = useState(false);
  const [open, setOpen] = useState(false);
  const [isPreloader, setIsPreloader] = useState(true);
  const [foGotEmail, setFoGotEmail] = useState('');
  const [windowInnerWidth, setWindowInnerWidth] = useState(window.innerWidth);
  const userAgent = window.navigator.userAgent || window.navigator.vendor || window.opera;
  const platform = window.navigator.platform;
  const isMobileDevice = /Mobi|Android/i.test(navigator.userAgent);
  const iOS = /iPad|iPhone|iPod/.test(userAgent) || (platform === 'MacIntel' && navigator.maxTouchPoints > 1);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let userToken = localStorage.getItem('token-user');
  let adminToken = localStorage.getItem('token-admin');
  let is_appLocal = localStorage.getItem('is_app');

  useEffect(() => {
    handleStartLambda(dispatch);
    if (isApp && userToken?.length && !adminToken?.length) {
      verifyToken(userToken);
    } else if (isApp && !userToken?.length && adminToken?.length) {
      verifyToken(adminToken);
    } else {
      setIsPreloader(false);
    }

    if (isApp || is_appLocal) {
      localStorage.setItem('is_app', JSON.stringify(true));
    }

    if (iOS) {
      handleInitApple();
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setIsErrorEmail(false);
    setIsErrorRestoreEmail(false);
    setIsErrorPassword(false);
  }, [password, email]);

  const handleResize = () => {
    setWindowInnerWidth(window.innerWidth);
  };

  const handleChange = () => {
    let test1 = email?.length && validEmail(email) ? true : false;
    let test2 = password?.length && validatePassword(password) ? true : false;
    if (test1 && test2) {
      setIsPreloader(true);
      let data = {
        email: email.trim(),
        password: password,
        is_mobile_device: isMobileDevice && windowInnerWidth < 640 ? true : false,
      };

      fetchRequest('POST', `/auth/login`, data).then((res) => {
        if (res?.success && res.data?.token?.length) {
          handleUpdateData(res.data);
        } else {
          dispatch(setShowMessageObj({ open: true, status: 'error', message: res?.statusCode === 401 ? translations['errorLogin'][language] : res?.statusCode === 402 ? translations['errorLogin'][language] : translations['anErrorOccurred'][language] }));
          logIssue(WARNING, "handleChange SignInView", res);
        }
        setIsPreloader(false);
      });
    } else {
      setIsErrorEmail(!test1 && true);
      setIsErrorPassword(!test2 && true);
    }
  };

  const handleGoogleLogin = async (googleData) => {
    setIsPreloader(true);
    const res = await fetch(`${process.env.REACT_APP_BASE_URL}/auth/login`, {
      method: 'POST',
      body: JSON.stringify({ ...googleData, clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID, is_mobile_device: isMobileDevice && windowInnerWidth < 640 ? true : false }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const resData = await res.json();
    if (!resData.success) {
      dispatch(setShowMessageObj({ open: true, status: 'error', message: resData?.statusCode === 401 ? translations['errorLogin'][language] : resData?.statusCode === 402 ? translations['errorLogin'][language] : translations['anErrorOccurred'][language] }));
      logIssue(WARNING, "handleGoogleLogin SignInView", resData);
    } else {
      handleUpdateData(resData.data);
    }
    setIsPreloader(false);
  };

  const handleAppleSignIn = async () => {
    try {
      const response = await window.AppleID.auth.signIn();
      console.log("Успішна авторизація через Apple:", response);

      // Отримуємо авторизаційний код або ID токен
      const { authorization } = response;
      console.log("Authorization Code:", authorization.code);
      console.log("ID Token:", authorization.id_token);

      // Відправляємо дані на сервер
      await handleAppleLogin(authorization);
    } catch (error) {
      console.error("Помилка авторизації через Apple:", error);
    }
  };

  const handleAppleLogin = async (authorization) => {
    try {
      setIsPreloader(true);
      const res = await fetch(`${process.env.REACT_APP_BASE_URL}/auth/login`, {
        method: 'POST',
        body: JSON.stringify({ ...authorization, is_mobile_device: isMobileDevice && windowInnerWidth < 640 ? true : false }),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const resData = await res.json();
      if (!resData.success) {
        dispatch(setShowMessageObj({ open: true, status: 'error', message: resData?.statusCode === 401 ? translations['errorLogin'][language] : resData?.statusCode === 402 ? translations['errorLogin'][language] : translations['anErrorOccurred'][language] }));
        logIssue(WARNING, "handleAppleLogin SignInView", resData);
      } else {
        handleUpdateData(resData.data);
      }
      setIsPreloader(false);
    } catch (error) {
      console.error("Error response:", error);
      logIssue(WARNING, "handleAppleLogin SignInView", error);
    }
  };

  const handleUpdateData = (obj) => {
    const { user, token } = obj;
    const userType = user.is_coach ? 'coach' : 'user';
    const tokenKey = userType === 'coach' ? 'token-admin' : 'token-user';
    const navigatePath = userType === 'coach' ? `/auth/${user._id}/trainings` : `/user/${user._id}`;

    dispatch(userType === 'coach' ? setCoach(user) : setUser(user));
    localStorage.setItem(tokenKey, token);

    navigate(isApp ? `${navigatePath}?is_app=true` : navigatePath);
    handleDeleteToken(isApp, user);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleFogotPassword = (boolean) => {
    if (boolean) {
      if (foGotEmail?.length && validEmail(foGotEmail)) {
        setIsPreloader(true);
        let data = {
          email: foGotEmail.trim(),
        };

        fetchRequest('POST', `/auth/restore`, data).then((res) => {
          if (res?.success) {
            dispatch(setShowMessageObj({ open: true, status: 'success', message: translations['messageSent'][language] }));
            setFoGotEmail('');
            setOpen(false);
          } else {
            dispatch(setShowMessageObj({ open: true, status: 'error', message: res?.statusCode === 401 ? translations['errorLoginEmail'][language] : translations['anErrorOccurred'][language] }));
            logIssue(WARNING, "handleFogotPassword SignInView", res);
          }
          setIsPreloader(false);
        });
      } else {
        setIsErrorRestoreEmail((!foGotEmail?.length || !validEmail(foGotEmail)) && true);
      }
    } else {
      setOpen(false);
      setFoGotEmail('');
    }
  };

  const verifyToken = async (token) => {
    setIsPreloader(true);
    if (token?.length) {
      fetchGetData(`/auth/verify?token=${token}`).then((res) => {
        if (res?.success && res.data?._id?.length) {
          if (res.data?.is_coach) {
            navigate(`/auth/${res.data._id}/trainings?is_app=true`);
            localStorage.setItem('is_app', JSON.stringify(true));
            localStorage.removeItem('token-user');
            dispatch(setCoach(res.data));
          } else {
            navigate(`/user/${res.data?._id}?is_app=true`);
            localStorage.setItem('is_app', JSON.stringify(true));
            localStorage.removeItem('token-admin');
            dispatch(setUser(res.data));
          }
        }
        setIsPreloader(false);
      });
    }
  };

  const handleClick = () => {
    if (window.flutter_inappwebview) {
      window.flutter_inappwebview.callHandler('googleSignUp', 'login');
    }
  };

  return (
    <div className="sign-in-wrap">
      <PageMeta {...pageMeta['SignInView']} />
      {open && (
        <ModalWindow
          title={translations['restoringPassword'][language]}
          handleClick={handleFogotPassword}
          rightBtn={translations['send'][language]}
          leftBtn={translations['cancel'][language]}
          isRightBtnPreloader={isPreloader}
        >
          <TextInput
            setValue={setFoGotEmail}
            value={foGotEmail}
            label="Email"
            helperText={isErrorRestoreEmail ? (!foGotEmail.length ? translations['errorEmail1'][language] : translations['errorEmail'][language]) : ''}
          />
        </ModalWindow>
      )}
      {windowInnerWidth < 960 ? (
        <div className="sign-in container">
          <div className="sign-in__form-wrap">
            {isPreloader && (
              <PreloaderCustom
                newStyle={{ height: '100vh' }}
                isNewStyleLoader={true}
              />
            )}
            <GoogleOAuthProvider clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}>
              <div className="sign-in__form-title-wrap">
                {isApp ? (
                  <div className="sign-in__form-btn-back sign-in__form-btn-back--app">
                    <div className="sign-in__form-title sign-in__form-title--app">{translations['logIn'][language]}</div>
                  </div>
                ) : (
                  <button
                    className="sign-in__form-btn-back"
                    onClick={() => navigate(-1)}
                  >
                    <img
                      className="sign-in__form-btn-back-img"
                      src={arrowLeft}
                      alt="img"
                    />
                    <div className="sign-in__form-title">{translations['logIn'][language]}</div>
                  </button>
                )}
                <SelectLanguage isSignIn={true} />
              </div>
              <div className="sign-in__form">
                <div className="sign-in__input-form--wrap">
                  <div className="sign-in__form-input-wrap">
                    <TextInput
                      setValue={setEmail}
                      value={email}
                      label="Email"
                      helperText={isErrorEmail ? (email.length && validEmail(email) ? translations['errorEmail'][language] : translations['errorEmail3'][language]) : ''}
                    />
                    <PasswordInput
                      password={password}
                      label={translations['password'][language]}
                      setPassword={setPassword}
                      handleKeyEnter={handleChange}
                      errorPassword={isErrorPassword ? (!password?.length ? translations['enterPassword'][language] : translations['errorPassword'][language]) : ''}
                    />
                  </div>
                  <div className="sign-in__form-btn-wrap">
                    <button
                      className="mainBtnDark mainBtn--whidth"
                      onClick={handleChange}
                    >
                      {translations['login'][language]}
                    </button>
                    <button
                      className="sign-in__form-btn-forget"
                      onClick={handleClickOpen}
                    >
                      {translations['forgotPassword'][language]}
                    </button>
                  </div>
                </div>
                <div className="sign-in__form-btn-create-wrap">
                  {!isApp ? (
                    <Box 
                      display={'flex'}
                      flexDirection={'column'}
                      alignItems={'center'}
                      justifyContent={'center'}
                      width={'100%'}
                      gap={'15px'}
                    >
                      <button className="sign-in__form-google-btn-wrap">
                        <img
                          className="sign-in__form-google-btn-img"
                          src={singInBtn}
                          alt="img"
                        />
                        <div className="sign-in__form-google-btn">
                          <GoogleLogin
                            onSuccess={handleGoogleLogin}
                            onError={handleGoogleLogin}
                            useOneTap
                            prompt="select_account"
                            // flow="implicit"
                            // ux_mode="redirect"
                          />
                        </div>
                      </button>
                      {
                        iOS && (
                          <button
                            onClick={handleAppleSignIn}
                            id="appleid-signin"
                            data-type="sign-in"             // Тип кнопки
                            data-color="white"              // Колір кнопки ("black" або "white")
                            data-border="true"              // Відображення рамки
                            data-border-radius="50px"       // Закруглення кутів
                            data-width="175px"              // Ширина кнопки
                            data-height="40px"              // Висота кнопки
                          ></button>
                        )
                      }
                    </Box>
                  ) : (
                    <button
                      className="sign-in__form-google-btn-wrap"
                      onClick={handleClick}
                    >
                      <img
                        className="sign-in__form-google-btn-img"
                        src={singInBtn}
                        alt="img"
                      />
                    </button>
                  )}
                  <NavLink
                    className="sign-in__form-btn-create"
                    to={isApp ? '/register?is_app=true' : '/register'}
                  >
                    {translations['createAccount'][language]}
                  </NavLink>
                </div>
              </div>
            </GoogleOAuthProvider>
          </div>
        </div>
      ) : (
        <div className="sign-in__desktop">
          <div className="sign-in__desktop-img-wrap">
            <NavLink to="/">
              <img
                className="sign-in__desktop-img-logo"
                src={logoWhite}
                alt="img"
              />
            </NavLink>
            <img
              className="sign-in__desktop-img"
              src={sportMan4}
              alt="img"
            />
          </div>
          <div className="sign-in__form-wrap">
            {isPreloader && (
              <PreloaderCustom
                newStyle={{ height: '100vh', with: '60%', maxWidth: window.innerWidth < 960 ? '100%' : '490px' }}
                isNewStyleLoader={true}
              />
            )}
            <GoogleOAuthProvider clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}>
              <div className="sign-in__desktop-form">
                <div className="sign-in__input-form--wrap">
                  <SelectLanguage isSignIn={true} />
                  <div className="sign-in__desktop-form-title">{translations['welcomeBack'][language]}</div>
                  <div className="sign-in__desktop-form-sub-title">{translations['startManaging'][language]}</div>
                  <div className="sign-in__desktop-form-input-wrap">
                    <TextInput
                      setValue={setEmail}
                      value={email}
                      label="Email"
                      helperText={isErrorEmail ? (email.length && validEmail(email) ? translations['errorEmail'][language] : translations['errorEmail3'][language]) : ''}
                    />
                    <PasswordInput
                      password={password}
                      label={translations['password'][language]}
                      setPassword={setPassword}
                      handleKeyEnter={handleChange}
                      errorPassword={isErrorPassword ? (!password?.length ? translations['enterPassword'][language] : translations['errorPassword'][language]) : ''}
                    />
                  </div>
                  <div className="sign-in__form-btn-wrap">
                    <button
                      className="sign-in__desktop-form-btn-forget"
                      onClick={handleClickOpen}
                    >
                      {translations['forgotPassword'][language]}
                    </button>
                    <button
                      className="mainBtnDark mainBtn--whidth"
                      onClick={handleChange}
                    >
                      {translations['login'][language]}
                    </button>
                  </div>
                  <div className="sign-in__desktop-form-or">
                    <div className="sign-in__desktop-form-or-text">or</div>
                  </div>
                  {!isApp ? (
                    <Box 
                      display={'flex'}
                      flexDirection={'column'}
                      alignItems={'center'}
                      justifyContent={'center'}
                      width={'100%'}
                      gap={'15px'}
                    >
                      <button className="sign-in__form-google-btn-wrap">
                        <img
                          className="sign-in__form-google-btn-img"
                          src={singInBtn}
                          alt="img"
                        />
                        <div className="sign-in__form-google-btn">
                          <GoogleLogin
                            onSuccess={handleGoogleLogin}
                            onError={handleGoogleLogin}
                            useOneTap
                            prompt="select_account"
                            // flow="implicit"
                            // ux_mode="redirect"
                          />
                        </div>
                      </button>
                      {
                        iOS && (
                          <button
                            onClick={handleAppleSignIn}
                            id="appleid-signin"
                            data-type="sign-in"             // Тип кнопки
                            data-color="white"              // Колір кнопки ("black" або "white")
                            data-border="true"              // Відображення рамки
                            data-border-radius="50px"       // Закруглення кутів
                            data-width="175px"              // Ширина кнопки
                            data-height="40px"              // Висота кнопки
                          ></button>
                        )
                      }
                      {/* {
                        true && (
                          <button
                            onClick={() => window.AppleID.auth.signIn()}
                            style={{
                              backgroundColor: "white",
                              color: "black",
                              border: "2px solid red",
                              borderRadius: "50px",
                              width: "175px",
                              height: "40px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              fontSize: "14px",
                              fontWeight: "bold",
                            }}
                          >
                            <img
                              src="https://developer.apple.com/design/human-interface-guidelines/sign-in-with-apple/images/SignInWithAppleMark.svg"
                              alt="Apple Logo"
                              style={{ width: "20px", marginRight: "10px" }}
                            />
                            Sign in with Apple
                          </button>
                        )
                      } */}
                    </Box>
                  ) : (
                    <button
                      className="sign-in__form-google-btn-wrap"
                      onClick={handleClick}
                    >
                      <img
                        className="sign-in__form-google-btn-img"
                        src={singInBtn}
                        alt="img"
                      />
                    </button>
                  )}
                </div>
                <div className="sign-in__desktop-form-btn--wrap">
                  <span className="sign-in__desktop-form-btn-text">{translations['loginToAccount'][language]}</span>
                  <NavLink
                    className="sign-in__desktop-form-btn"
                    to={isApp ? '/register?is_app=true' : '/register'}
                  >
                    {translations['signUp'][language]}
                  </NavLink>
                </div>
              </div>
            </GoogleOAuthProvider>
          </div>
        </div>
      )}
    </div>
  );
}

export default SignInView;
