import './AdminUsersView.css';
import { useEffect, useRef, useState } from 'react';
import UserCard from '../../components/UserCard/UserCard';
import ModalWindow from '../../components/ModalWindow/ModalWindow';
import TextInput from '../../components/TextInput/TextInput';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import DoubleButtons from '../../components/DoubleButtons/DoubleButtons';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { pageMeta } from '../../pageMeta';
import PageMeta from '../../components/PageMeta/PageMeta';
import { translations } from '../../localization';
import { useSelector } from 'react-redux';
import { fetchGetData, fetchRequest, handleGetAllTrainings, logIssue, validEmail } from '../../helpers/Utils';
import { useDispatch } from 'react-redux';
import PreloaderCustom from '../../components/PreloaderCustom/PreloaderCustom';
import { setIsModalStripeRegistration, setTrainings, setUsers } from '../../store/userSlice';
import { setShowMessageObj } from '../../store/homeSlice';
import InfiniteScroll from 'react-infinite-scroller';
import { useSearchParams } from 'react-router-dom';
import envelopeImage from '../../assets/envelopeImage.svg';
import notUserImg from '../../assets/notUserImg.svg';
import LoadingHasMore from '../../components/LoadingHasMore/LoadingHasMore';
import { WARNING } from '../../helpers/Config';

function AdminUsersView() {
  const [searchParams, setSearchParams] = useSearchParams();
  const newSearchParams = new URLSearchParams(searchParams);
  const searchValueSearchParams = JSON.parse(searchParams.get('searchValue'));
  const language = useSelector((state) => state.userSlice.language);
  const trainings = useSelector((state) => state.userSlice.trainings);
  const coach = useSelector((state) => state.userSlice.coach);
  const users = useSelector((state) => state.userSlice.users);
  const [isActive, setIsActive] = useState(false);
  const [isNewUser, setIsNewUser] = useState(false);
  const [isModalSuccess, setIsModalSuccess] = useState(false);
  const [isNewOffer, setIsNewOffer] = useState(true);
  const [newOfferUser, setNewOfferUser] = useState({});
  const [isPreloader, setIsPreloader] = useState(true);
  const [isLoadingHasMore, setIsLoadingHasMore] = useState(false);
  const [email, setEmail] = useState('');
  const [training, setTraining] = useState({});
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
  const day = currentDate.getDate().toString().padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;
  const [valueDate, setValueDate] = useState(dayjs(currentDate));
  const [valueMinDate, setValueMinDate] = useState(dayjs(currentDate).add(10, 'day'));
  const [page, setPage] = useState(0);
  const [isHasMore, setIsHasMore] = useState(false);
  const [isOpenTrening, setIsOpenTrening] = useState(false);
  const [isErrorEmail, setIsErrorEmail] = useState(false);
  const [isErrorTrening, setIsErrorTrening] = useState(false);
  const [isNotFirstRender, setIsNotFirstRender] = useState(false);
  const [isModalInvitations, setIsModalInvitations] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [timerId, setTimerId] = useState(null);
  const scrollParentRef = useRef(null);
  const dispatch = useDispatch();
  let token = localStorage.getItem('token-admin');

  useEffect(() => {
    setIsNotFirstRender(true);
    handleGetUsers(null, 0, searchValueSearchParams?.length ? searchValueSearchParams : '');
  }, []);

  useEffect(() => {
    setIsErrorEmail(false);
  }, [email]);

  useEffect(() => {
    if (training?._id?.length) {
      setIsErrorTrening(false);
    }
  }, [training]);

  useEffect(() => {
    if (trainings?.length && newOfferUser.training_id?.length) {
      let obj = trainings.filter((el) => el._id === newOfferUser.training_id)[0];
      setTraining(obj?._id?.length ? { ...obj } : {});

      if (obj?._id?.length) {
        if (new Date(newOfferUser.expired_at).getTime() > new Date().getTime()) {
          setValueDate(dayjs(newOfferUser.expired_at));
          setValueMinDate(dayjs(formattedDate).add(obj.days.length + 2, 'day'));
        } else if (obj?.days?.length) {
          setValueDate(dayjs(formattedDate).add(obj.days.length + 2, 'day'));
          setValueMinDate(dayjs(formattedDate).add(obj.days?.length + 2, 'day'));
        } else {
          setValueDate(dayjs(formattedDate).add(10, 'day'));
          setValueMinDate(dayjs(formattedDate).add(10, 'day'));
        }
      }
    }
  }, [trainings, newOfferUser]);

  useEffect(() => {
    if (isNotFirstRender) {
      createTimer();
    }
  }, [searchValueSearchParams]);

  const createTimer = () =>  {
    function startTimer() {
      if (timerId !== null) {
          clearTimeout(timerId);
      }

      setTimerId(setTimeout(() => {
        handleGetUsers(null, 0);
        setPage(0);
        setTimerId(null);
      }, 300));
    }

    return startTimer();
  }

  const handleIsNewUser = (boolean) => {
    if (boolean) {
      if (isNewUser && !isNewOffer) {
        // добавлення нового тренування користувачу
        // newOfferUser новий користувач
        handleAddTrening();
      } else {
        // добавлення нового користувача
        handleAddUser();
      }
    } else {
      handleClearInput();
    }
  };

  const handleClearInput = () => {
    setIsNewUser(false);
    setIsErrorEmail(false);
    setIsErrorTrening(false);
    setIsNewOffer(true);
    setNewOfferUser({});
    setTraining({});
    setEmail('');
    setValueDate(dayjs(currentDate));
    setValueMinDate(dayjs(currentDate).add(2, 'day'));
  };

  const handleSelectTrening = (str) => {
    let res = JSON.parse(str);
    setTraining(res);
    setValueDate(dayjs(formattedDate).add(res.days?.length + 2, 'day'));
    setValueMinDate(dayjs(formattedDate).add(res.days?.length + 2, 'day'));
  };

  const handleAddBodybuilder = () => {
    if (coach.stripe_activated) {
      setIsNewUser(true);
      handleGetTrainings();
    } else {
      dispatch(setIsModalStripeRegistration(true));
    }
  };

  const handleNewOffer = (obj) => {
    handleGetTrainings();
    setNewOfferUser(obj);
    setIsNewOffer(false);
    setIsNewUser(true);
  };

  const handleSeleckActive = (boolean) => {
    setIsActive(boolean);
    setPage(0);
    newSearchParams.set('searchValue', JSON.stringify(''));
    setIsHasMore(false);
    handleGetUsers(boolean, 0, '');
  };

  const handleSetTrainings = (res) => {
    if (res?.success && res?.data) {
      dispatch(setTrainings(res.data?.items?.length ? res.data?.items : []));
    }
  };

  const handleGetTrainings = () => {
    handleGetAllTrainings(token, null, null, '', '', true, handleSetTrainings);
  };

  const handleLoadMore = () => {
    setIsHasMore(false);
    if (isHasMore) {
      handleGetUsers(null, page + 1);
      setPage(page + 1);
    }
  };

  const handleGetUsers = (boolean = null, newPage = null, startSearchValue = null) => {
    // if (totalCount === 0 || boolean !== null || newPage === 0) {
    if (newPage === null || newPage === 0) {
      setIsPreloader(true);
    } else {
      setIsLoadingHasMore(true);
    }
    setIsHasMore(false);
    fetchGetData(`/assigned/all?is_active=${boolean !== null ? !boolean : !isActive}&page=${newPage !== null ? newPage : page}&search=${startSearchValue !== null ? startSearchValue : searchValueSearchParams?.length ? searchValueSearchParams : ''}&token=${token}`).then((res) => {
      if (res?.success && res?.data) {
        const newData = res.data?.items || [];
        const updatedData = newPage ? [...users, ...newData] : newData;
        dispatch(setUsers(updatedData));
        setIsHasMore(updatedData.length < res.data?.count);
        setTotalCount(res.data?.count || 0);
      }
      setIsPreloader(false);
      setIsLoadingHasMore(false);
    });
  };

  const handleAddTrening = () => {
    if (training?._id?.length) {
      let data = {
        assigned_id: !isNewOffer && !isActive ? newOfferUser.assigned_id : null,
        email: newOfferUser.email,
        user_id: newOfferUser._id,
        training_id: training?._id?.length ? training?._id : newOfferUser.training_id,
        expired_at: new Date(valueMinDate).getTime() > new Date(valueDate).getTime() ? new Date(new Date(valueMinDate).toISOString()).getTime() : new Date(new Date(valueDate).toISOString()).getTime(),
        coach_id: coach?._id,
      };
      handleFetchRequest(data, false);
    } else {
      setIsErrorTrening(!training?._id?.length ? true : false);
    }
  };

  const handleAddUser = () => {
    if (email?.length && validEmail(email) && training?._id?.length) {
      let data = {
        email: email,
        training_id: training?._id,
        expired_at: new Date(valueMinDate).getTime() > new Date(valueDate).getTime() ? new Date(new Date(valueMinDate).toISOString()).getTime() : new Date(new Date(valueDate).toISOString()).getTime(),
        coach_id: coach?._id,
      };
      handleFetchRequest(data, true);
    } else {
      setIsErrorEmail(!email?.length || !validEmail(email) ? true : false);
      setIsErrorTrening(!training?._id?.length ? true : false);
    }
  };

  const handleFetchRequest = (data, isNewUser = false) => {
    fetchRequest('POST', `/assigned?token=${token}`, data).then((res) => {
      if (res?.success && res?.data) {
        if (isNewUser) {
          dispatch(setShowMessageObj({ open: true, status: 'success', message: translations['userAdded'][language] }));
        } else {
          dispatch(setShowMessageObj({ open: true, status: 'success', message: translations['dataUpdated'][language] }));
        }
        if (!isActive) {
          handleGetUsers(null, 0);
        }
        handleClearInput();
      }
      if (!res?.success && res?.statusCode === 401) {
        setIsErrorEmail(true);
        setIsModalInvitations(true);
        logIssue(WARNING, "handleAddUser AdminUsersView", res);
      }
      if (!res?.success && res?.statusCode === 402) {
        setIsErrorTrening(true);
        logIssue(WARNING, "handleAddUser AdminUsersView", res);
      }
      if (!res?.success && res?.statusCode === 400) {
        dispatch(setShowMessageObj({ open: true, status: 'error', message: translations['anErrorOccurred'][language] }));
        logIssue(WARNING, "handleAddUser AdminUsersView", res);
      }
      setIsPreloader(false);
    });
  };

  const handleIsModalInvitations = (boolean) => {
    if (boolean) {
      setIsPreloader(true);
      let data = {
        email: email,
      };
      fetchRequest('POST', `/auth/send-invitation?token=${token}`, data).then((res) => {
        if (res?.success) {
          dispatch(setShowMessageObj({ open: true, status: 'success', message: translations['messageSent'][language] }));
        } else {
          dispatch(setShowMessageObj({ open: true, status: 'error', message: translations['anErrorOccurred'][language] }));
          logIssue(WARNING, "handleIsModalInvitations AdminUsersView", res);
        }
        setIsNewUser(false);
        setIsModalInvitations(false);
        setIsPreloader(false);
        handleClearInput();
      });
    } else {
      setIsModalInvitations(false);
    }
  };

  return (
    <div className="admin-users-wrap">
      <PageMeta {...pageMeta['AdminUsersView']} />
      {isPreloader && <PreloaderCustom />}
      {isNewUser && (
        <ModalWindow
          title={isNewOffer ? translations['addBodybuilder'][language] : !isActive ? translations['changeExpireDate'][language] : translations['offerNewTraining'][language]}
          handleClick={handleIsNewUser}
          rightBtn={isNewOffer ? translations['addUser'][language] : !isActive ? translations['change'][language] : translations['addOffer'][language]}
        >
          <div className="admin-users__modal-input-wrap">
            {isNewOffer && (
              <TextInput
                setValue={setEmail}
                value={email}
                label={translations['enterUserEmail'][language]}
                helperText={isErrorEmail ? (!email?.length ? translations['errorEmail1'][language] : email?.length && !validEmail(email) ? translations['errorEmail'][language] : translations['notFoundUser'][language]) : ''}
              />
            )}
            <FormControl
              fullWidth
              className={`${!isActive && !isNewOffer ? 'disabledContainer' : ''}`}
            >
              <InputLabel id="admin-users__modal-trening-label">{translations['training'][language]}</InputLabel>
              <Select
                open={isOpenTrening}
                onOpen={() => setIsOpenTrening(true)}
                onClose={() => setIsOpenTrening(false)}
                labelId="admin-users__modal-trening-label"
                value={JSON.stringify(training)}
                label={translations['training'][language]}
                onChange={(e) => handleSelectTrening(e.target.value)}
                className={`admin-users__modal-trening-select ${training?._id?.length || isOpenTrening ? 'mu-component-is-active' : ''}`}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      maxHeight: 200,
                      overflowY: 'auto',
                      '&::-webkit-scrollbar': {
                        width: '4px',
                      },
                    },
                  },
                }}
              >
                <MenuItem value={JSON.stringify({})}>{translations['pleaseSelectPlan'][language]}</MenuItem>
                {!!trainings?.length &&
                  trainings.map((el, i) => (
                    <MenuItem
                      key={el._id}
                      value={JSON.stringify(el)}
                    >
                      {el.name}
                    </MenuItem>
                  ))}
              </Select>
              {isErrorTrening && (
                <FormHelperText
                  className="password-input__error"
                  id="outlined-weight-helper-text"
                >
                  {!training?._id?.length ? translations['errorTrening'][language] : translations['errorTrening1'][language]}
                </FormHelperText>
              )}
            </FormControl>
            <div className={`admin-users__modal-input-date-wrap ${!training?._id?.length && isNewOffer ? 'disabledContainer' : ''}`}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker']}>
                  <DatePicker
                    minDate={dayjs(valueMinDate)}
                    defaultValue={dayjs(valueMinDate)}
                    value={valueDate}
                    onChange={(newValue) => setValueDate(newValue)}
                    label={translations['finishTraining'][language]}
                    views={['year', 'month', 'day']}
                    inputFormat="DD/MM/YYYY"
                    slotProps={{
                      textField: {
                        helperText: 'MM/DD/YYYY',
                      },
                    }}
                    // locale={ua}
                    className={`${valueMinDate ? 'mu-component-is-active' : ''}`}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </div>
          </div>
        </ModalWindow>
      )}
      {isModalInvitations && (
        <ModalWindow
          title={''}
          handleClick={handleIsModalInvitations}
          isRightBtnPreloader={isPreloader}
        >
          <img
            className="admin-users__modal-invitations-img"
            src={envelopeImage}
            alt="envelope"
          />
          <p className="admin-users__modal-invitations-title">{translations['notRegisteredYet'][language]}</p>
          <p className="admin-users__modal-invitations-text">{translations['sendInvatation'][language]}</p>
        </ModalWindow>
      )}
      {isModalSuccess && (
        <ModalWindow
          title={''}
          handleClick={() => setIsModalSuccess(false)}
          notBtn={true}
        >
          <svg
            className="restore-password__modal-img"
            width="136"
            height="136"
            viewBox="0 0 136 136"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <mask
              x="0"
              y="0"
              width="136"
              height="136"
            >
              <rect
                width="136"
                height="136"
                fill="rgba(143, 127, 243, 1)"
              />
            </mask>
            <g mask="url(#mask0_409_1912)">
              <path
                d="M57.1317 97.5248L105.458 49.1984L100.111 43.8513L57.1317 86.8307L35.5984 65.2974L30.2513 70.6444L57.1317 97.5248ZM68.0253 136C58.622 136 49.7814 134.216 41.5034 130.647C33.2254 127.078 26.0247 122.235 19.9011 116.117C13.7777 110 8.93004 102.806 5.35802 94.5355C1.78601 86.2652 0 77.4285 0 68.0253C0 58.622 1.78431 49.7814 5.35292 41.5034C8.92166 33.2254 13.7649 26.0247 19.8826 19.9011C26.0004 13.7777 33.1943 8.93004 41.4645 5.35803C49.7348 1.78601 58.5715 0 67.9747 0C77.378 0 86.2186 1.78431 94.4966 5.35292C102.775 8.92166 109.975 13.7649 116.099 19.8826C122.222 26.0004 127.07 33.1943 130.642 41.4645C134.214 49.7348 136 58.5715 136 67.9747C136 77.378 134.216 86.2186 130.647 94.4966C127.078 102.775 122.235 109.975 116.117 116.099C110 122.222 102.806 127.07 94.5355 130.642C86.2652 134.214 77.4285 136 68.0253 136ZM68 128.444C84.8741 128.444 99.1667 122.589 110.878 110.878C122.589 99.1667 128.444 84.8741 128.444 68C128.444 51.1259 122.589 36.8333 110.878 25.1222C99.1667 13.4111 84.8741 7.55556 68 7.55556C51.1259 7.55556 36.8333 13.4111 25.1222 25.1222C13.4111 36.8333 7.55556 51.1259 7.55556 68C7.55556 84.8741 13.4111 99.1667 25.1222 110.878C36.8333 122.589 51.1259 128.444 68 128.444Z"
                fill="rgba(143, 127, 243, 1)"
              />
            </g>
          </svg>
          <div className="restore-password__modal-title">{translations['modalSentNotification'][language]}</div>
          <div className="restore-password__modal-btn-wrap">
            <button
              className="mainBtnDark restore-password__modal-btn"
              onClick={() => setIsModalSuccess(false)}
            >
              {translations['close'][language]}
            </button>
          </div>
        </ModalWindow>
      )}
      <div className="admin-users">
        <div className="admin-users__main-wrap">
          <div className="admin-users__btn--wrap">
            <div className="admin-users__count-wrap">
              <div className="admin-users__count">{totalCount}</div>
              <div className="admin-users__count-text">{!isActive ? translations['activeBodybuilders'][language] : translations['archiveBodybuilders'][language]}</div>
            </div>
            <div className="admin-users__btn-wrap">
              <DoubleButtons
                isActive={!isActive}
                leftBtnText={translations['activeBodybuilders'][language]}
                rightBtnText={translations['archiveBodybuilders'][language]}
                onClickLeftBtn={() => handleSeleckActive(false)}
                onClickRightBtn={() => handleSeleckActive(true)}
              />
            </div>
            <div className="admin-users__price-wrap"></div>
          </div>
          <div className="admin-users__users--wrap">
            {!!users?.length ? (
              <div
                className={`admin-users__users-wrap ${isActive ? 'admin-users__users-wrap--is-active' : ''}`}
                ref={scrollParentRef}
              >
                <InfiniteScroll
                  pageStart={0}
                  useWindow={false}
                  getScrollParent={() => scrollParentRef.current}
                  loadMore={handleLoadMore}
                  hasMore={isHasMore}
                  className="admin-users__users"
                >
                  <div className="admin-users__users-header-wrap">
                    <div className="admin-users__users-header-name-wrap">
                      <div className="admin-users__users-header-name">{translations['name'][language]}</div>
                    </div>
                    <div className="admin-users__users-header-training">{!isActive ? translations['training'][language] : translations['lastTraining'][language]}</div>
                    {!isActive ? (
                      <>
                        <div className="admin-users__users-header-finish">{translations['daysToFinish'][language]}</div>
                        <div className="admin-users__users-header-expire">{translations['expireIn'][language]}</div>
                        <div className="admin-users__users-header-expire">{translations['price'][language]}</div>
                      </>
                    ) : (
                      <div className="admin-users__users-header-training"></div>
                    )}
                    <div className={`admin-users__users-header-btn`}>{translations['more'][language]}</div>
                  </div>
                  {users.map((el, index) => (
                    <UserCard
                      key={`${el._id}-${index}`}
                      user={el}
                      index={index}
                      isActive={isActive}
                      handleClick={() => handleNewOffer(el)}
                      setTotalCount={setTotalCount}
                    />
                  ))}
                  {
                    isLoadingHasMore && <LoadingHasMore />
                  }
                </InfiniteScroll>
              </div>
            ) : (
              <>
                {!!searchValueSearchParams?.length ? (
                  <div className="admin-all-plans__empty-wrap">{translations['noSearchResult'][language]}</div>
                ) : (
                  <div className="admin-users__empty-img-wrap">
                    <img
                      className="admin-users__empty-img"
                      // src={windowInnerWidth < 640 ? notUserImg : notUserDesImg}
                      src={notUserImg}
                      alt="img"
                    />
                    <div className="admin-all-plans__empty-wrap">{translations['userListEmpty'][language]}</div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <div className={`admin-users__add-btn-wrap ${isActive ? 'displayNone' : ''}`}>
          <button
            className="mainBtnDark"
            onClick={handleAddBodybuilder}
          >
            + {translations['addBodybuilder'][language]}
          </button>
        </div>
      </div>
    </div>
  );
}

export default AdminUsersView;
