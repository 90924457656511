import './UserProfileView.css';
import { useEffect, useRef, useState } from 'react';
import { setShowMessageObj, setUser } from '../../store/homeSlice';
import { useDispatch } from 'react-redux';
import TextInput from '../../components/TextInput/TextInput';
import { useParams, useSearchParams } from 'react-router-dom';
import PasswordInput from '../../components/PasswordInput/PasswordInput';
import PhoneInput from 'react-phone-input-2';
import { pageMeta } from '../../pageMeta';
import PageMeta from '../../components/PageMeta/PageMeta';
import { useSelector } from 'react-redux';
import { fetchGetData, fetchRequest, handleDeleteAccount, handleFocusAppInput, handleFormatDateWeek, handleSaveMedia, handleSendUpdateEmail, handleUploadImgCompressor, logIssue, validEmail, validatePassword, validatePhone } from '../../helpers/Utils';
import PreloaderCustom from '../../components/PreloaderCustom/PreloaderCustom';
import { translations } from '../../localization';
import ModalWindow from '../../components/ModalWindow/ModalWindow';
import editViolet from '../../assets/editViolet.svg';
import defaultAvatar from '../../assets/defaultAvatar.png';
import editBgWhite from '../../assets/editBgWhite.svg';
import excelImg from '../../assets/excelImg.svg';
import downloadImg2 from '../../assets/downloadImg2.svg';
import CountdownTimer from '../../components/CountdownTimer/CountdownTimer';
import Cropper from 'react-easy-crop';
import { Popover, Slider, Typography } from '@mui/material';
import getCroppedImg from '../../helpers/cropImage';
import { setIsInputFocus } from '../../store/userSlice';
import { format } from 'date-fns';
import helpIcon from '../../assets/helpIcon.svg';
import { WARNING } from '../../helpers/Config';

function UserProfileView() {
  const [searchParams, setSearchParams] = useSearchParams();
  const newSearchParams = new URLSearchParams(searchParams);
  const is_infoSearchParams = searchParams.get('is_info');
  const language = useSelector((state) => state.homeSlice.language);
  const windowInnerWidth = useSelector((state) => state.homeSlice.windowInnerWidth);
  const user = useSelector((state) => state.homeSlice.user);
  const is_app = useSelector((state) => state.homeSlice.is_app);
  const [firstName, setFirstName] = useState(user?.first_name?.length ? user?.first_name : '');
  const [lastName, setLastName] = useState(user?.last_name?.length ? user?.last_name : '');
  const [email, setEmail] = useState(user?.email?.length ? user?.email : '');
  const [password, setPassword] = useState('123457');
  const [existingPassword, setExistingPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [phone, setPhone] = useState(user?.phone?.length ? user?.phone : '');
  const [location, setLocation] = useState(user?.location?.length ? user?.location : '');
  const [country, setCountry] = useState('');
  const [image, setImage] = useState(user?.image?.length ? user?.image : '');
  const [newImage, setNewImage] = useState({});
  const [isPreloader, setIsPreloader] = useState(true);
  const [isModalNewPassword, setIsModalNewPassword] = useState(false);
  const [isModalCreatePassword, setIsModalCreatePassword] = useState(false);
  const [isErrorPassword, setIsErrorPassword] = useState(false);
  const [isErrorConfirmPassword, setIsErrorConfirmPassword] = useState(false);
  const [isErrorNewEmail, setIsErrorNewEmail] = useState(false);
  const [isErrorFirstName, setIsErrorFirstName] = useState(false);
  const [isErrorLastName, setIsErrorLastName] = useState(false);
  const [isErrorPhone, setIsErrorPhone] = useState(false);
  const [isNewEmail, setIsNewEmail] = useState(false);
  const [isStartTimer, setIsStartTimer] = useState(false);
  const [isModalNewEmail, setIsModalNewEmail] = useState(false);
  const [newEmail, setNewEmail] = useState('');
  const [anchorImageEl, setAnchorImageEl] = useState(null);
  const [isEditeImage, setIsEditeImage] = useState(false);
  const [isUploadImg, setIsUploadImg] = useState(false);
  const [isBtnLoader, setIsBtnLoader] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [isModalDeleteAccount, setIsModalDeleteAccount] = useState(null);
  const [isInfo, setIsInfo] = useState(is_infoSearchParams?.length ? JSON.parse(is_infoSearchParams) : true);
  const [transactions, setTransactions] = useState([
    {
      _id: 1,
      created: '2021-01-01 00:00:00',
      amount: 1000,
      receipt_url: 'https://pay.stripe.com/receipts/payment/CAcaFwoVYWNjdF8xUGdRa1hScGtZaVhEbHc3KJHljbUGMgbN0bEopCk6LBYH2mw5eGqFP_3-V0MjzAJZO3pEIunl4Ys9jZ0Aug8JFcoMZ_R-SCPPguLN',
    },
    {
      _id: 2,
      created: '2021-05-01 00:00:00',
      amount: 1550,
      receipt_url: 'https://pay.stripe.com/receipts/payment/CAcaFwoVYWNjdF8xUGdRa1hScGtZaVhEbHc3KJHljbUGMgbN0bEopCk6LBYH2mw5eGqFP_3-V0MjzAJZO3pEIunl4Ys9jZ0Aug8JFcoMZ_R-SCPPguLN',
    },
  ]);
  const [isModalDownload, setIsModalDownload] = useState(false);
  const dispatch = useDispatch();
  const timeoutRef = useRef(null);
  const { userId } = useParams();
  let token = localStorage.getItem('token-user');

  useEffect(() => {
    handleCheckLocalNewEmail();
    // handleGetTransactions()

    return () => {
      if (timeoutRef.current) {
        clearInterval(timeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (user._id?.length) {
      setEmail(user.email);
      setPhone(user.phone);
      setLastName(user.last_name);
      setFirstName(user.first_name);
      setLocation(user.location);
      setImage(user.image);
      setIsPreloader(false);
    }
  }, [user]);

  useEffect(() => {
    setIsErrorPassword(false);
    setIsErrorConfirmPassword(false);
  }, [existingPassword, confirmPassword, newPassword]);

  useEffect(() => {
    setIsErrorFirstName(false);
  }, [firstName]);

  useEffect(() => {
    setIsErrorLastName(false);
  }, [lastName]);

  useEffect(() => {
    setIsErrorPhone(false);
  }, [phone]);

  useEffect(() => {
    setIsErrorNewEmail(false);
  }, [newEmail]);

  const handleSave = async () => {
    let testPhone = phone?.length && validatePhone(phone, country) ? false : true;
    let testFirstName = firstName?.length ? false : true;
    let testLastName = lastName?.length ? false : true;
    if (firstName?.length && lastName?.length && password?.length && (!testPhone || !phone?.length)) {
      if (newImage?.name?.length) {
        let str = await handleSaveMedia(token, newImage);
        if (str?.length) {
          handleUpdateUser(str);
        }
        return;
      }
      handleUpdateUser();
    } else {
      setIsErrorPhone(testPhone);
      setIsErrorFirstName(testFirstName);
      setIsErrorLastName(testLastName);
      dispatch(
        setShowMessageObj({
          open: true,
          status: 'error',
          message: `${translations['userUpdate'][language]} ${testFirstName ? translations['firstName'][language] : ''} ${testLastName ? translations['lastName'][language] : ''} ${testPhone ? translations['phone'][language] : ''}`,
        }),
      );
    }
  };

  const handleUpdateUser = (str = '') => {
    setIsPreloader(true);
    let data = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone: phone,
      location: location,
      image: str?.length ? str : image,
    };
    fetchRequest('PUT', `/users/${user?._id}?token=${token}`, data).then((res) => {
      if (res?.success && res.data) {
        dispatch(setUser(res.data));
        dispatch(setShowMessageObj({ open: true, status: 'success', message: translations['dataUpdated'][language] }));
        handleClearInput();
      } else {
        dispatch(setShowMessageObj({ open: true, status: 'error', message: translations['anErrorOccurred'][language] }));
        logIssue(WARNING, "handleUpdateUser UserProfileView", res);
      }
      setIsPreloader(false);
    });
  };

  const handleIsModalNewPassword = (boolean) => {
    if (boolean) {
      if (existingPassword?.length) {
        let data = {
          email: user.email,
          password: existingPassword,
        };
        fetchRequest('POST', `/auth/check?token=${token}`, data).then((res) => {
          if (res?.success && res.data) {
            setIsModalCreatePassword(true);
            setIsModalNewPassword(false);
            setIsErrorPassword(false);
          } else {
            setIsErrorPassword(true);
          }
        });
      }
    } else {
      handleClearInput();
    }
  };

  const handleUpdateUserPassword = () => {
    let data = {
      _id: user?._id,
      new_password: newPassword,
    };
    fetchRequest('POST', `/auth/update-password?token=${token}`, data).then((res) => {
      if (res?.success && res.data) {
        dispatch(setShowMessageObj({ open: true, status: 'success', message: translations['passwordUpdated'][language] }));
      } else {
        dispatch(setShowMessageObj({ open: true, status: 'error', message: translations['anErrorOccurred'][language] }));
        logIssue(WARNING, "handleUpdateUserPassword UserProfileView", res);
      }
      setIsModalCreatePassword(false);
      setExistingPassword('');
      setConfirmPassword('');
      setNewPassword('');
    });
  };

  const handleIsModalCreatePassword = (boolean) => {
    if (boolean) {
      if (newPassword === confirmPassword && newPassword !== existingPassword && validatePassword(newPassword)) {
        handleUpdateUserPassword();
      } else {
        setIsErrorPassword(true);
        setIsErrorConfirmPassword(newPassword === confirmPassword ? true : false);
      }
    } else {
      handleClearInput();
    }
  };

  const handleClearInput = () => {
    setIsModalCreatePassword(false);
    setIsModalNewPassword(false);
    setIsErrorPassword(false);
    setIsErrorConfirmPassword(false);
    setNewPassword('');
    setConfirmPassword('');
    setExistingPassword('');
    setIsModalNewEmail(false);
    setIsErrorNewEmail(false);
  };

  const handlePhoneChange = (value, country) => {
    setPhone(value);
    setCountry(country.countryCode);
  };

  const handleUploadImg = async (file = null) => {
    if (file && file[0]?.name?.length) {
      setIsUploadImg(true);
      if (window.innerWidth < 640) {
        let obj = await handleUploadImgCompressor(file);
        if (obj !== null) {
          let str = await handleSaveMedia(token, obj);
          if (str?.length) {
            setImage(str);
          }
        }
      } else {
        let str = await handleSaveMedia(token, file[0]);
        if (str?.length) {
          setImage(str);
        }
      }
      setIsUploadImg(false);
    }
  };

  const handleIsUpdateEmail = (res) => {
    if (res?.success && res.data) {
      handleClearInput();
      localStorage.setItem('newUserEmail', JSON.stringify(newEmail));
      localStorage.setItem('newUserEmailTime', JSON.stringify(new Date().getTime()));
      setIsNewEmail(true);
      dispatch(setShowMessageObj({ open: true, status: 'success', message: translations['messageSent'][language] }));
    } else {
      dispatch(setShowMessageObj({ open: true, status: 'error', message: res?.statusCode === 401 ? translations['errorRegisterEmail'][language] : translations['anErrorOccurred'][language] }));
      setIsErrorNewEmail(true);
      logIssue(WARNING, "handleIsUpdateEmail UserProfileView", res);
    }
    setIsPreloader(false);
  };

  const handleUpdateEmail = () => {
    setIsPreloader(true);
    let data = {
      email: newEmail,
    };
    handleSendUpdateEmail(token, data, handleIsUpdateEmail);
  };

  const handleResendMail = () => {
    let newUser = handleGetUser();
    if (newUser?._id?.length && newUser?.email === newEmail) {
      handleCleanLocal();
    } else {
      handleUpdateEmail();
      setIsStartTimer(true);
    }
  };

  const handleIsModalNewEmail = (boolean) => {
    if (boolean) {
      if (newEmail?.length && validEmail(newEmail)) {
        handleUpdateEmail();
      } else {
        setIsErrorNewEmail(newEmail?.length && validEmail(newEmail) ? false : true);
      }
    } else {
      handleClearInput();
      if (!isNewEmail) {
        setNewEmail('');
      }
    }
  };

  const handleGetUser = async () => {
    let newUser = await fetchGetData(`/users/${userId}?token=${token}`).then((res) => {
      if (res?.success && res?.data) {
        dispatch(setUser(res.data));
        return res.data;
      } else {
        logIssue(WARNING, "handleGetUser UserProfileView", res);
        return null;
      }
    });
    return newUser;
  };

  const handleCleanLocal = () => {
    setNewEmail('');
    setIsNewEmail(false);
    localStorage.removeItem('newUserEmail');
    localStorage.removeItem('newUserEmailTime');
    localStorage.removeItem('startTime');
    localStorage.removeItem('savedTimer');
  };

  const handleCheckLocalNewEmail = async () => {
    let localNewEmail = localStorage.getItem('newUserEmail');
    if (localNewEmail?.length) {
      let email = JSON.parse(localNewEmail);
      if (email?.length && user._id?.length && email === user.email) {
        handleCleanLocal();
        return;
      }
      if (email?.length && user._id?.length && email !== user.email) {
        let res = await handleGetUser();
        if (res?._id?.length && res?.email === email) {
          handleCleanLocal();
          if (timeoutRef.current) {
            clearInterval(timeoutRef.current);
            timeoutRef.current = null;
          }
          return;
        }
        setIsNewEmail(true);
        setNewEmail(email);
        const startTime = localStorage.getItem('startTime');
        const savedTimer = localStorage.getItem('savedTimer');
        if (startTime && savedTimer) {
          setIsStartTimer(true);
        }
        timeoutRef.current = setTimeout(() => {
          handleCheckLocalNewEmail();
        }, 3000);
      }
    }
  };

  const handleClosePopover = () => {
    setAnchorImageEl(null);
    setIsEditeImage(true);
  };

  const handleIsEditeImage = (boolean) => {
    if (boolean) {
      showCroppedImage();
    } else {
      setIsEditeImage(false);
    }
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const showCroppedImage = async () => {
    try {
      const croppedImage = await getCroppedImg(image, croppedAreaPixels, rotation);
      setNewImage(croppedImage);
      setIsEditeImage(false);
    } catch (e) {
      console.error(e);
      logIssue(WARNING, "showCroppedImage UserProfileView", e);
    }
  };

  const handleSelectTab = (boolean) => {
    setIsInfo(boolean);
    newSearchParams.set('is_info', JSON.stringify(boolean));
    setSearchParams(newSearchParams);
  };

  const handleIsDownload = (boolean) => {
    if (boolean) {
      handleDownloadTransactions();
    } else {
      setIsModalDownload(false);
    }
  };

  const handleDownloadTransactions = () => {
    setIsPreloader(true);
    fetchGetData(`/download-transactions?token=${token}`).then((res) => {
      if (res?.success && res?.data) {
        dispatch(setShowMessageObj({ open: true, status: 'success', message: translations['messageSent'][language] }));
      } else {
        dispatch(setShowMessageObj({ open: true, status: 'error', message: translations['anErrorOccurred'][language] }));
        logIssue(WARNING, "handleDownloadTransactions UserProfileView", res);
      }
      setIsModalDownload(false);
      setIsPreloader(false);
    });
  };

  const handleGetTransactions = () => {
    setIsPreloader(true);
    fetchGetData(`/stripe/transactions/${user.stripe_id}?token=${token}`).then((res) => {
      if (res?.success && res?.data) {
        setTransactions(res.data);
      } else {
        dispatch(setShowMessageObj({ open: true, status: 'error', message: translations['anErrorOccurred'][language] }));
        logIssue(WARNING, "handleGetTransactions UserProfileView", res);
      }
      setIsPreloader(false);
    });
  };

  const handleIsModalDeleteAccount = (boolean) => {
    if (boolean) {
      setIsBtnLoader(true);
      handleDeleteAccount(token, user._id, is_app, false, dispatch, language, () => setIsBtnLoader(false));
    }
    setIsModalDeleteAccount(false);
  };

  return (
    <div className="user-profile-wrap">
      <PageMeta {...pageMeta['UserProfileView']} />
      {isModalDeleteAccount && (
        <ModalWindow
          title={''}
          handleClick={handleIsModalDeleteAccount}
        >
          <div className="admin-trainings__dialogform-delete-wrap">
            <img
              className="admin-trainings__dialogform-delete-img"
              src={helpIcon}
              alt="img"
            />
            <div className="admin-trainings__dialogform-delete-text">
              <div>{translations['AreYouSureDeleteAccount'][language]}</div>
            </div>
          </div>
        </ModalWindow>
      )}
      {isPreloader && (
        <PreloaderCustom
          newStyle={{ height: `100vh`, left: `0`, top: '0' }}
          isNewStyleLoader={true}
        />
      )}
      {isModalDownload && (
        <ModalWindow
          title={''}
          handleClick={handleIsDownload}
          isRightBtnPreloader={isPreloader}
        >
          <div className="admin-trainings__dialogform-delete-wrap">
            <img
              className="admin-trainings__dialogform-delete-img"
              src={downloadImg2}
              alt="img"
            />
            <div className="admin-trainings__dialogform-delete-text">
              <div>{translations['downloadExcel'][language]}</div>
            </div>
          </div>
        </ModalWindow>
      )}
      {isEditeImage && (
        <ModalWindow
          title={''}
          handleClick={handleIsEditeImage}
          addStyles={{
            width: '100%',
            maxWidth: windowInnerWidth < 640 ? '100%' : '90%',
            height: '100%',
            maxHeight: windowInnerWidth < 640 ? '100%' : '90%',
            borderRadius: windowInnerWidth < 640 ? '0px' : '27px',
            padding: windowInnerWidth < 640 ? '16px 10px' : '16px 24px',
          }}
        >
          <div className="admin-training__modal-edite-image">
            {!!image?.length && (
              <div className="admin-training__create-img-cropper-wrap">
                <Cropper
                  image={image?.length ? image : ''}
                  crop={crop}
                  zoom={zoom}
                  rotation={rotation}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                  onRotationChange={setRotation}
                  cropSize={{ width: 150, height: 150 }}
                  minZoom={0.1}
                  zoomSpeed={0.05}
                  objectFit={'cover'}
                />
              </div>
            )}
            <div className="admin-training__modal-edite-image-slider">
              <div className="admin-training__modal-edite-image-slider-wrap">
                <Typography variant="overline">{translations['zoom'][language]}</Typography>
                <Slider
                  value={zoom}
                  min={0.1}
                  max={3}
                  step={0.1}
                  aria-labelledby="Zoom"
                  onChange={(e, zoom) => setZoom(zoom)}
                  className="admin-training__modal-edite-slider"
                />
              </div>
              <div className="admin-training__modal-edite-image-slider-wrap">
                <Typography variant="overline">{translations['rotation'][language]}</Typography>
                <Slider
                  value={rotation}
                  min={0}
                  max={360}
                  step={1}
                  aria-labelledby="Rotation"
                  onChange={(e, rotation) => setRotation(rotation)}
                  className="admin-training__modal-edite-slider"
                />
              </div>
            </div>
          </div>
        </ModalWindow>
      )}
      {isModalNewPassword && (
        <ModalWindow
          title={translations['changePassword'][language]}
          handleClick={handleIsModalNewPassword}
        >
          <div className="user-profile__modal-input-wrap">
            <PasswordInput
              password={existingPassword}
              setPassword={setExistingPassword}
              handleKeyEnter={() => handleIsModalNewPassword(true)}
              errorPassword={isErrorPassword ? translations['errorPassword1'][language] : ''}
            />
          </div>
        </ModalWindow>
      )}
      {isModalCreatePassword && (
        <ModalWindow
          title={translations['changePassword'][language]}
          handleClick={handleIsModalCreatePassword}
          rightBtn={translations['save'][language]}
        >
          <div className="user-profile__modal-input-wrap">
            <PasswordInput
              password={newPassword}
              setPassword={setNewPassword}
              label={translations['newPassword'][language]}
              errorPassword={isErrorPassword ? (!newPassword?.length ? translations['enterPassword'][language] : newPassword === existingPassword ? translations['errorPassword2'][language] : !validatePassword(newPassword) ? translations['errorPassword'][language] : '') : ''}
            />
            <PasswordInput
              password={confirmPassword}
              setPassword={setConfirmPassword}
              label={translations['confirmNewPassword'][language]}
              handleKeyEnter={() => handleIsModalCreatePassword(true)}
              errorPassword={isErrorConfirmPassword ? translations['passwordsNotMatch'][language] : ''}
            />
          </div>
        </ModalWindow>
      )}
      {isModalNewEmail && (
        <ModalWindow
          title={translations['changeEmail'][language]}
          handleClick={handleIsModalNewEmail}
          rightBtn={translations['send'][language]}
          isRightBtnPreloader={isPreloader}
        >
          <div className="admin-profile__modal-input-wrap">
            <TextInput
              setValue={setEmail}
              value={email}
              label={translations['currentEmail'][language]}
              newStyle={{ maxWidth: '100%' }}
              isDisabled={true}
            />
            <TextInput
              setValue={setNewEmail}
              value={newEmail}
              label={translations['newEmail'][language]}
              newStyle={{ maxWidth: '100%' }}
              helperText={isErrorNewEmail ? (!email?.length ? translations['errorEmail1'][language] : !!email?.length && !validEmail(email) ? translations['errorEmail'][language] : translations['errorEmail2'][language]) : ''}
            />
          </div>
        </ModalWindow>
      )}
      <div className="user-profile container">
        <div className="user-profile__input--wrap">
          <div className="user-profile__mobile-img--wrap">
            <div className="user-profile__mobile-img-wrap">
              <div className="user-profile__img-wrap">
                {newImage?.name?.length ? (
                  <img
                    className="user-profile__input-img"
                    src={URL.createObjectURL(newImage)}
                    alt="img"
                  />
                ) : (
                  <>
                    <img
                      className="user-profile__input-img"
                      src={image?.length ? image : defaultAvatar}
                      alt="img"
                    />
                    {!image?.length && (
                      <div className="user-profile__img-text">
                        {user?.first_name?.slice(0, 1)}
                        {user?.last_name?.slice(0, 1)}
                      </div>
                    )}
                  </>
                )}
              </div>
              <button
                className="user-profile__mobile-img-btn-wrap"
                onClick={(e) => setAnchorImageEl(e.currentTarget)}
                disabled={isUploadImg}
              >
                <img
                  className="user-profile__mobile-img-btn"
                  src={editBgWhite}
                  alt="img"
                />
              </button>
              <Popover
                id={'user-profile__popover-edite-image'}
                open={Boolean(anchorImageEl)}
                anchorEl={anchorImageEl}
                onClose={() => setAnchorImageEl(null)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 20,
                }}
                classes={{
                  root: 'upload-image__popover-wrap',
                }}
              >
                <div className="upload-image__popover">
                  <button
                    className={`upload-image__popover-btn ${!image?.length ? 'disabledContainer' : ''}`}
                    onClick={handleClosePopover}
                  >
                    {translations['editImage'][language]}
                  </button>
                  <label
                    className="upload-image__popover-label"
                    htmlFor="creationtImgExercise"
                    onClick={() => setAnchorImageEl(null)}
                  >
                    {translations['uploadFromComputer'][language]}
                  </label>
                </div>
              </Popover>
              <input
                className="create-exercise__create-img-link-upload-input"
                onChange={(e) => handleUploadImg(e.target.files)}
                type="file"
                accept="image/*"
                id="creationtImgExercise"
              />
            </div>
            <div className="admin-profile__mobile-input-wrap admin-profile__mobile-input-name-wrap">
              <TextInput
                setValue={setFirstName}
                value={firstName}
                label={translations['firstName'][language]}
                helperText={isErrorFirstName ? translations['fieldRequired'][language] : ''}
              />
              <TextInput
                setValue={setLastName}
                value={lastName}
                label={translations['lastName'][language]}
                helperText={isErrorLastName ? translations['fieldRequired'][language] : ''}
              />
            </div>
          </div>
          {/* <div className='admin-profile__info-tab-wrap user-profile__info-tab-wrap'>
                  <button 
                      className={`admin-profile__info-tab ${isInfo ? 'admin-profile__info-tab--active' : ''}`}
                      onClick={() => handleSelectTab(true)}
                  >
                      {translations['info'][language]}
                  </button>
                  <button 
                      className={`admin-profile__info-tab ${!isInfo ? 'admin-profile__info-tab--active' : ''}`}
                      onClick={() => handleSelectTab(false)}
                  >
                      {translations['billing'][language]}
                  </button>
              </div> */}
          {!isInfo ? (
            <>
              {!!transactions?.length ? (
                <>
                  <button
                    className="billing-view__btn-download"
                    onClick={() => setIsModalDownload(true)}
                  >
                    <img
                      className="billing-view__btn-download-img"
                      src={excelImg}
                      alt="img"
                    />
                    <span>{translations['transactions'][language]}</span>
                  </button>
                  <div className="billing-view__transactions-wrap user-profile__transactions-wrap">
                    {transactions.map((el, index) => (
                      <div
                        className="billing-view__transactions"
                        key={el}
                      >
                        <div className="billing-view__transactions-top">
                          <div className="billing-view__transactions-title">{handleFormatDateWeek(el.created, language)}</div>
                          <div className="billing-view__transactions-title">${el.amount / 100}</div>
                        </div>
                        <div className="billing-view__transactions-bottom">
                          <div className="billing-view__transactions-text">{format(new Date(el.created), 'MM-dd-yyyy')}</div>
                          <a
                            className="mainBtnDark user-profile__transactions-btn"
                            href={el.receipt_url}
                            target="_blank"
                          >
                            {translations['watch'][language]}
                          </a>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                <div className="user-profile__no-transactions">{translations['noTransactions'][language]}</div>
              )}
            </>
          ) : (
            <div className="user-profile__input-wrap">
              {/* <TextInput
                setValue={setFirstName}
                value={firstName}
                label={translations['firstName'][language]}
                helperText={isErrorFirstName ? translations['fieldRequired'][language] : ''}
              />
              <TextInput
                setValue={setLastName}
                value={lastName}
                label={translations['lastName'][language]}
                helperText={isErrorLastName ? translations['fieldRequired'][language] : ''}
              /> */}
              <div className={`user-profile__input-password-wrap ${user?.is_google_auth ? 'displayNone' : ''}`}>
                <TextInput
                  setValue={setEmail}
                  value={newEmail?.length && isNewEmail ? newEmail : email}
                  label="Email"
                  isDisabled={true}
                />
                {isNewEmail && (
                  <button
                    className={`admin-profile__btn-resend ${isStartTimer ? 'admin-profile__btn-resend--disabled' : ''}`}
                    onClick={handleResendMail}
                  >
                    <span>{translations['resendMail'][language]}</span>
                    {isStartTimer && <CountdownTimer setIsStartTimer={setIsStartTimer} />}
                  </button>
                )}
                <button
                  className="admin-profile__btn-edite"
                  onClick={() => setIsModalNewEmail(true)}
                >
                  <img
                    src={editViolet}
                    alt="edit"
                  />
                </button>
              </div>
              <div className={`user-profile__input-container ${user?.is_google_auth ? 'displayNone' : ''}`}>
                <div className="user-profile__input-password-wrap">
                  <TextInput
                    setValue={setPassword}
                    value={'********'}
                    label={translations['password'][language]}
                    isDisabled={true}
                  />
                  <button
                    className={`admin-profile__btn-edite`}
                    onClick={() => setIsModalNewPassword(true)}
                  >
                    <img
                      src={editViolet}
                      alt="edit"
                    />
                  </button>
                </div>
              </div>
              <div className="sign-up__form-phone-wrap">
                <PhoneInput
                  className={`sign-up__form-phone ${phone?.length > 4 ? 'sign-up__form-phone--is-active' : ''}`}
                  country={'ua'}
                  value={!phone?.length ? '380' : phone}
                  onChange={handlePhoneChange}
                  onFocus={() => handleFocusAppInput(true, (res) => dispatch(setIsInputFocus(res), is_app))}
                  onBlur={() => handleFocusAppInput(false, (res) => dispatch(setIsInputFocus(res), is_app))}
                />
                {isErrorPhone && <div className="sign-up__form-phone-error">{translations['errorPhone'][language]}</div>}
              </div>
              <TextInput
                setValue={setLocation}
                value={location}
                label={`${translations['location'][language]} (${translations['optional'][language]})`}
              />
              <div className="user-profile__input-btn-wrap">
                <button
                  className="mainBtnDark user-profile__input-btn"
                  onClick={handleSave}
                >
                  {translations['save'][language]}
                </button>
              </div>
              <div className="user-profile__input-btn-wrap">
                <button
                  className="mainBtnWhite"
                  onClick={() => setIsModalDeleteAccount(true)}
                  disabled={isBtnLoader}
                >
                  {
                    isBtnLoader &&
                      <span className="coaching-day-view__btn-loader user-profile__btn-loader"></span>
                  }
                  <span>{translations['deleteAccount'][language]}</span>
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default UserProfileView;
