import './AdminTrainingView.css';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import TextInput from '../../components/TextInput/TextInput';
import deleteImgViolet from '../../assets/deleteImgViolet.svg';
import ModalWindow from '../../components/ModalWindow/ModalWindow';
import editViolet from '../../assets/editViolet.svg';
import copyImgViolet from '../../assets/copyImgViolet.svg';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import DoubleButtons from '../../components/DoubleButtons/DoubleButtons';
import { useSelector } from 'react-redux';
import { fetchGetData, fetchRequest, handleSaveMedia, handleUploadImgCompressor, logIssue } from '../../helpers/Utils';
import { pageMeta } from '../../pageMeta';
import PageMeta from '../../components/PageMeta/PageMeta';
import { translations } from '../../localization';
import { setTrainingObj, setTrainings } from '../../store/userSlice';
import { setShowMessageObj } from '../../store/homeSlice';
import { useDispatch } from 'react-redux';
import PreloaderCustom from '../../components/PreloaderCustom/PreloaderCustom';
import { NEW, WARNING } from '../../helpers/Config';
import { Box, FormControl, FormHelperText, InputAdornment, InputLabel, OutlinedInput, Slider, Stack, Typography } from '@mui/material';
import helpIcon from '../../assets/helpIcon.svg';
import { handleValidURL } from '../../helpers/Utils';
import Cropper from 'react-easy-crop';
import getCroppedImg from '../../helpers/cropImage';
import UploadImage from '../../components/UploadImage/UploadImage';
import ResultImage from '../../components/ResultImage/ResultImage';
import moveUpImg2 from '../../assets/moveUpImg2.svg';

function AdminTrainingView() {
  const [searchParams, setSearchParams] = useSearchParams();
  const newSearchParams = new URLSearchParams(searchParams);
  const { trainingId } = useParams();
  const tabSearchParams = JSON.parse(searchParams.get('tab'));
  const windowInnerWidth = useSelector((state) => state.userSlice.windowInnerWidth);
  const coach = useSelector((state) => state.userSlice.coach);
  const language = useSelector((state) => state.userSlice.language);
  const trainingObj = useSelector((state) => state.userSlice.trainingObj);
  const trainings = useSelector((state) => state.userSlice.trainings);
  const [planName, setPlanName] = useState('');
  const [daysCount, setDaysCount] = useState(trainingObj?.days?.length ? trainingObj?.days?.length : 0);
  const [price, setPrice] = useState('');
  const [newImage, setNewImage] = useState(trainingObj?.newImage?.length && trainingObj?._id === trainingId ? trainingObj.newImage : '');
  const [image, setImage] = useState('');
  const [imageLink, setImageLink] = useState('');
  const [isModalDelete, setIsModalDelete] = useState(false);
  const [isPreloader, setIsPreloader] = useState(false);
  const [isModalImageUrl, setIsModalImageUrl] = useState(false);
  const [isErrorImageLink, setIsErrorImageLink] = useState(false);
  const [isEditeImage, setIsEditeImage] = useState(false);
  const [isUploadImg, setIsUploadImg] = useState(false);
  const [deleteDay, setDeleteDay] = useState(null);
  const [isMainTab, setIsMainTab] = useState(tabSearchParams === false ? false : tabSearchParams === true ? true : true);
  const [days, setDays] = useState(trainingObj?.days?.length ? [...trainingObj.days] : []);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [errorPrice, setErrorPrice] = useState('');
  const [errorPlanName, setErrorPlanName] = useState('');
  const [errorImage, setErrorImage] = useState('');
  const { coachId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let token = localStorage.getItem('token-admin');

  useEffect(() => {
    if (trainingId?.length && trainingId !== NEW && (!trainingObj?._id?.length || trainingObj?._id !== trainingId)) {
      handleGetTraining();
    } else {
      handleSetInput();
      handleGetTotalTime();
    }

    return () => {};
  }, []);

  useEffect(() => {
    if (tabSearchParams !== null && isMainTab !== tabSearchParams) {
      setIsMainTab(tabSearchParams);
    }
  }, [tabSearchParams]);

  useEffect(() => {
    handleGetTotalTime();
  }, [trainingObj]);

  useEffect(() => {
    if (imageLink?.length) {
      if (!handleValidURL(imageLink)) {
        setIsErrorImageLink(true);
      } else {
        setIsErrorImageLink(false);
      }
    } else {
      setIsErrorImageLink(false);
    }
    dispatch(
      setTrainingObj({
        ...trainingObj,
        name: planName,
        image: image,
        newImage: newImage,
        price: price,
      }),
    );
    if (planName?.length) {
      setErrorPlanName('');
    }
    if (price || price?.length) {
      setErrorPrice('');
    }
    if (image?.length || newImage?.length) {
      setErrorImage('');
    }
  }, [imageLink, planName, image, newImage, price]);

  const handleGetTotalTime = () => {
    if (trainingObj?.days?.length) {
      let res = 0;
      trainingObj.days.forEach((el) => {
        if (el?.exercises?.length && el?.exercises[0] !== null) {
          res += el.exercises.reduce((acc, el) => acc + ((el?.time || 0) * (el?.number_sets || 1)), 0);
        }
      });
      return Math.round(res);
    }
  };

  const handleSelectTab = (boolean) => {
    setIsMainTab(boolean);
    newSearchParams.set('tab', JSON.stringify(boolean));
    setSearchParams(newSearchParams);
  };

  const handleUploadImg = async (file = null) => {
    if (file && file[0]?.name?.length) {
      setIsUploadImg(true);
      if (window.innerWidth < 640) {
        let obj = await handleUploadImgCompressor(file);
        if (obj !== null) {
          let str = await handleSaveMedia(token, obj);
          if (str?.length) {
            setImage(str);
          }
        }
      } else {
        let str = await handleSaveMedia(token, file[0]);
        if (str?.length) {
          setImage(str);
        }
      }
      setIsUploadImg(false);
    }
  };

  const handleTimeDay = (obj) => {
    let res = 0;
    if (obj?.exercises?.length && obj?.exercises[0] !== null) {
      res = obj.exercises.reduce((acc, el) => acc + (el?.time || 0) * (el?.number_sets || 1), 0);
    }
    return res;
  };

  const handleEdite = (obj) => {
    navigate(`/auth/${coachId}/trainings/${trainingId}/${obj._id}`);
  };

  const handleDelete = (e, obj) => {
    e.stopPropagation();
    setIsModalDelete(true);
    setDeleteDay(obj);
  };

  const handleIsDeleteDay = (boolean) => {
    if (boolean) {
      let res = [...days.filter((el) => el._id !== deleteDay._id)];
      setDays(res.map((el, i) => ({ ...el, order: (i + 1).toString() })));
    }
    setIsModalDelete(false);
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedItems = Array.from(days);
    const [removed] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, removed);

    setDays(reorderedItems);
    dispatch(
      setTrainingObj({
        ...trainingObj,
        days: reorderedItems.map((el, i) => {
          return { ...el, order: (i + 1).toString() };
        }),
      }),
    );
  };

  const handleUpdateTraining = async () => {
    if (trainingObj.image.length && trainingObj?.name?.length && Number(trainingObj.price) > 0) {
      setIsPreloader(true);
      let data = {};
      const excludedFields = ['newImage'];
      for (let key in trainingObj) {
        if (!excludedFields.includes(key)) {
          data[key] = trainingObj[key];
        }
      }
      if (newImage?.length) {
        data.image = newImage;
      }
      data.days = days?.length ? [...days] : [];
      data.coach_id = coach._id;
      data.coach = coach.first_name + ' ' + coach.last_name;

      fetchRequest('PUT', `/trainings/${trainingId}?token=${token}`, data).then((res) => {
        if (res?.success && res.data) {
          dispatch(setTrainings([...trainings.map((el) => (el._id === trainingId ? res.data : el))]));
          dispatch(setShowMessageObj({ open: true, status: 'success', message: translations['trainingUpdated'][language] }));
          navigate(`/auth/${coachId}/trainings`);
        } else {
          dispatch(setShowMessageObj({ open: true, status: 'error', message: res?.statusCode === 401 ? translations['errorName'][language] : translations['anErrorOccurred'][language] }));
          logIssue(WARNING, "handleUpdateTraining AdminTrainingView", res);
        }
        setIsPreloader(false);
      });
    } else {
      setErrorPrice((!trainingObj.price || trainingObj.price <= 0) ? translations['fieldRequired'][language] : '');
      setErrorPlanName(!trainingObj.name?.length ? translations['fieldRequired'][language] : '');
      setErrorImage((!trainingObj.image && !newImage?.length) ? translations['fieldRequired'][language] : '');
      dispatch(setShowMessageObj({ open: true,
        status: 'error', 
        message: `${translations['errorExercise'][language]} 
          ${(!trainingObj.price || trainingObj.price <= 0) ? translations['price'][language] + ',' : ''}
          ${!trainingObj?.name?.length ? translations['trainingName'][language] + ',' : ''}
          ${(!trainingObj.image && !newImage?.length) ? translations['titleImage'][language] : ''} 
        `}));
    }
  };

  const handleCreate = async () => {
    if ((trainingObj.image.length || newImage?.length) && trainingObj?.name?.length && trainingObj.price > 0) {
      setIsPreloader(true);
      let data = {...trainingObj};
      // let data = {};
      // const excludedFields = ['newImage'];
      // for (let key in trainingObj) {
      //   if (!excludedFields.includes(key)) {
      //     data[key] = trainingObj[key];
      //   }
      // }
      if (newImage?.length) {
        data.image = newImage;
      }
      data.coach_id = coach._id;
      data.coach = coach.first_name + ' ' + coach.last_name;
      data.price = Number(trainingObj.price);

      fetchRequest('POST', `/trainings?token=${token}`, data).then((res) => {
        if (res?.success && res.data) {
          navigate(`/auth/${coachId}/trainings`);
          dispatch(setTrainings([res.data, ...trainings]));
          dispatch(setTrainingObj({}));
          dispatch(setShowMessageObj({ open: true, status: 'success', message: translations['trainingCreated'][language] }));
        } else {
          dispatch(setShowMessageObj({ open: true, status: 'error', message: res?.statusCode === 401 ? translations['errorName'][language] : translations['anErrorOccurred'][language] }));
          logIssue(WARNING, "handleCreate trainings AdminTrainingView", res);
        }
        setIsPreloader(false);
      });
    } else {
      setErrorPrice((!trainingObj.price || trainingObj.price <= 0) ? translations['fieldRequired'][language] : '');
      setErrorPlanName(!trainingObj.name?.length ? translations['fieldRequired'][language] : '');
      setErrorImage((!trainingObj.image && !newImage?.length) ? translations['fieldRequired'][language] : '');
      dispatch(setShowMessageObj({ open: true,
        status: 'error', 
        message: `${translations['errorExercise'][language]} 
          ${(!trainingObj.price || trainingObj.price <= 0) ? translations['price'][language] + ',' : ''}
          ${!trainingObj?.name?.length ? translations['trainingName'][language] + ',' : ''}
          ${(!trainingObj.image && !newImage?.length) ? translations['titleImage'][language] : ''} 
        `}));
    }
  };

  const handleCopt = (e, obj) => {
    e.stopPropagation();
    if (obj?._id?.length) {
      dispatch(
        setTrainingObj({
          ...trainingObj,
          days: [...trainingObj.days, { _id: (trainingObj.days?.length + 1).toString(), order: (trainingObj.days?.length + 1).toString(), name: `${translations['day'][language]} ${trainingObj.days?.length + 1}`, exercises: [...obj.exercises] }],
        }),
      );
      navigate(`/auth/${coachId}/trainings/${trainingId}/${trainingObj.days?.length + 1}`);
    }
  };

  const handleUpdateAddDay = () => {
    navigate(`/auth/${coachId}/trainings/${trainingId}/${trainingObj.days?.length + 1}`);
  };

  const handleAddDay = () => {
    navigate(`/auth/${coachId}/trainings/create/1`);
  };

  const handleSetInput = (obj) => {
    setPlanName(obj?.name?.length ? obj?.name : trainingObj?.name || '');
    setImage(obj?.image?.length ? obj?.image : trainingObj?.image || '');
    setPrice(obj?.price ? obj?.price + '' : trainingObj?.price ? trainingObj?.price + '' : '0');
    setDaysCount(obj?.days?.length ? obj?.days?.length : trainingObj?.days?.length ? trainingObj?.days?.length : 0);
    setDays(obj?.days?.length ? obj?.days : trainingObj?.days ? trainingObj?.days : []);
    setNewImage(obj?.newImage?.length ? obj?.newImage : trainingObj?.newImage || '');
  };

  const handleGetTraining = async () => {
    setIsPreloader(true);
    fetchGetData(`/trainings/${trainingId}?token=${token}`).then((res) => {
      if (res?.success && res?.data) {
        // dispatch(setTrainingObj(res.data));
        dispatch(setTrainingObj({
          ...res?.data, 
          days: res?.data?.days?.map((el) => ({ 
            ...el, 
            exercises: el.exercises.filter((exercise) => exercise?._id?.length) 
          }))
        }));
        handleSetInput(res.data);
      } else {
        navigate(`/auth/${coachId}/not-found`);
        dispatch(setShowMessageObj({ open: true, status: 'error', message: translations['anErrorOccurred'][language] }));
        logIssue(WARNING, "handleGetTraining AdminTrainingView", res);
      }
      setIsPreloader(false);
    });
  };

  const handleModalImageUrl = () => {
    setIsModalImageUrl(true);
  };

  const handleIsModalImageUrl = async (boolean) => {
    if (boolean && imageLink?.length && handleValidURL(imageLink)) {
      setIsPreloader(true);
      let res = await handleCopyImage(imageLink);
      setIsPreloader(false);
      if (res?.length) {
        setImage(res);
        setImageLink('');
        setIsModalImageUrl(false);
      } else {
        setImage('');
      }
      setNewImage('');
    } else {
      setImageLink('');
      setIsModalImageUrl(false);
    }
  };

  const handleCopyImage = async (imageLink) => {
    let data = {
      url: imageLink,
    };
    return await fetchRequest('POST', `/files/copy-image?token=${token}`, data).then((res) => {
      if (res?.success) {
        return res.data;
      } else {
        dispatch(setShowMessageObj({ open: true, status: 'error', message: translations['errorUseLink'][language] }));
        logIssue(WARNING, "handleCopyImage AdminTrainingView", res);
        return null;
      }
    });
  };

  const handleIsEditeImage = (boolean) => {
    if (boolean) {
      showCroppedImage();
    } else {
      setIsEditeImage(false);
    }
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const showCroppedImage = async () => {
    try {
      const croppedImage = await getCroppedImg(image?.length ? image : imageLink?.length ? imageLink : '', croppedAreaPixels, rotation);
      let str = await handleSaveMedia(token, croppedImage);
      if (str?.length) {
        setNewImage(str);
      }
      setIsEditeImage(false);
    } catch (e) {
      console.error(e);
      logIssue(WARNING, "showCroppedImage AdminTrainingView", e);
    }
  };

  const handleDeleteImage = () => {
    setNewImage('');
    setImageLink('');
    setImage('');
  };

  const handlePriceChange = (e) => {
    const input = e.target.value;
    const isDecimalValid = input.match(/^[0-9]*\.?[0-9]{0,2}$/);
    if (isDecimalValid || input === '') {
      setPrice(input);
    }
  };

  const  handleGetResultImageProps = () => {
    if (newImage?.length) {
      return { image: newImage };
    }
    if (imageLink?.length && !isModalImageUrl && !image?.length && !newImage?.length) {
      return { image: imageLink };
    }
    if (image?.length && (!imageLink?.length || (imageLink?.length && isModalImageUrl)) && !newImage?.length) {
      return { image: image };
    }
    return null;
  };

  return (
    <div className="admin-training-wrap">
      <PageMeta {...pageMeta['AdminTrainingView']} />
      {isPreloader && <PreloaderCustom />}
      {isEditeImage && (
        <ModalWindow
          title={''}
          handleClick={handleIsEditeImage}
          addStyles={{
            width: '100%',
            maxWidth: windowInnerWidth < 640 ? '100%' : '90%',
            height: '100%',
            maxHeight: windowInnerWidth < 640 ? '100%' : '90%',
            borderRadius: windowInnerWidth < 640 ? '0px' : '27px',
            padding: windowInnerWidth < 640 ? '16px 10px' : '16px 24px',
          }}
        >
          <div className="admin-training__modal-edite-image">
            {(!!image?.length || !!imageLink?.length) && (
              <div className="admin-training__create-img-cropper-wrap">
                <Cropper
                  image={image?.length ? image : imageLink?.length ? imageLink : ''}
                  crop={crop}
                  zoom={zoom}
                  rotation={rotation}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                  onRotationChange={setRotation}
                  cropSize={windowInnerWidth > 540 ? { width: 430, height: 238 } : { width: 287, height: 159 }}
                  minZoom={0.1}
                  zoomSpeed={0.05}
                  objectFit={'cover'}
                />
              </div>
            )}
            <div className="admin-training__modal-edite-image-slider">
              <div className="admin-training__modal-edite-image-slider-wrap">
                <Typography variant="overline">{translations['zoom'][language]}</Typography>
                <Slider
                  value={zoom}
                  min={0.1}
                  max={3}
                  step={0.1}
                  aria-labelledby="Zoom"
                  onChange={(e, zoom) => setZoom(zoom)}
                  className="admin-training__modal-edite-slider"
                />
              </div>
              <div className="admin-training__modal-edite-image-slider-wrap">
                <Typography variant="overline">{translations['rotation'][language]}</Typography>
                <Slider
                  value={rotation}
                  min={0}
                  max={360}
                  step={1}
                  aria-labelledby="Rotation"
                  onChange={(e, rotation) => setRotation(rotation)}
                  className="admin-training__modal-edite-slider"
                />
              </div>
            </div>
          </div>
        </ModalWindow>
      )}
      {isModalDelete && (
        <ModalWindow
          title={''}
          handleClick={handleIsDeleteDay}
        >
          <div className="admin-trainings__dialogform-delete-wrap">
            <img
              className="admin-trainings__dialogform-delete-img"
              src={helpIcon}
              alt="img"
            />
            <div className="admin-trainings__dialogform-delete-text">
              {!!deleteDay.name?.length ? (
                <>
                  <div>{translations['deleteThisDay'][language]}</div>
                  <div className="admin-trainings__dialogform-delete-text-name">{deleteDay.name}</div>
                </>
              ) : (
                <div>{translations['deleteRestTime'][language]}</div>
              )}
            </div>
          </div>
        </ModalWindow>
      )}
      {isModalImageUrl && (
        <ModalWindow
          title={'Link to image'}
          handleClick={handleIsModalImageUrl}
          isRightBtnPreloader={isPreloader}
        >
          <TextInput
            setValue={setImageLink}
            value={imageLink}
            label={translations['mainPreview'][language]}
            newStyle={{ maxWidth: '100%' }}
            helperText={isErrorImageLink ? translations['linkNotCorrect'][language] : null}
          />
        </ModalWindow>
      )}
      <div className="admin-training">
        <div className="admin-training__input-wrap">
          {!!trainingId?.length && (
            <div className="admin-training__btn--wrap">
              <div className="admin-training__count-wrap"></div>
              <div className="admin-training__btn-wrap">
                <DoubleButtons
                  isActive={isMainTab}
                  leftBtnText={translations['main'][language]}
                  rightBtnText={translations['days'][language]}
                  onClickLeftBtn={() => handleSelectTab(true)}
                  onClickRightBtn={() => handleSelectTab(false)}
                />
              </div>
              <div className="admin-training__price-wrap">
                <div className="admin-training__price">{handleGetTotalTime()}</div>
                <div className="admin-training__price-text">{translations['totalMinutes'][language]}</div>
              </div>
            </div>
          )}
          {isMainTab && (
            <div className="admin-training__input--wrap">
              <Stack direction={'column'}>
                <TextInput
                  setValue={setPlanName}
                  value={planName}
                  label={translations['trainingName'][language]}
                  newStyle={{ maxWidth: '100%' }}
                />
                {
                  !!errorPlanName?.length && (
                    <Box pl={'14px'}>
                      <FormHelperText>{errorPlanName}</FormHelperText>
                    </Box>
                  )
                }
              </Stack>
              {!!trainingId?.length && (
                <div className="disabledContainer">
                  <TextInput
                    setValue={setDaysCount}
                    value={daysCount}
                    label={`${translations['duration'][language]} (${translations['days'][language]})`}
                    newStyle={{ maxWidth: '100%' }}
                  />
                </div>
              )}
              <FormControl>
                <InputLabel htmlFor="create-exercise__price-input">{translations['price'][language]}</InputLabel>
                <OutlinedInput
                  id="create-exercise__price-input"
                  startAdornment={<InputAdornment position="start">$</InputAdornment>}
                  label={translations['price'][language]}
                  type="text"
                  value={price}
                  onChange={handlePriceChange}
                  onFocus={(e) => {
                    if (price == 0) {
                      setPrice('');
                    }
                  }}
                  inputProps={{ min: 0 }}
                />
                {
                  !!errorPrice?.length && (
                    <FormHelperText >{errorPrice}</FormHelperText>
                  )
                }
              </FormControl>
              {((!imageLink?.length && !image?.length && !newImage?.length) || isModalImageUrl) && (
                <UploadImage
                  title={translations['downloadImage'][language]}
                  handleModalImageUrl={handleModalImageUrl}
                  handleUploadImg={handleUploadImg}
                  isUploadImg={isUploadImg}
                  isError={errorImage}
                />
              )}
              {handleGetResultImageProps() && (
                <ResultImage
                  image={handleGetResultImageProps().image}
                  handleDelete={handleDeleteImage}
                  handleOpenEdite={() => setIsEditeImage(true)}
                  text={translations['mainPreviewImg'][language]}
                  classEnd={'main'}
                />
              )}
            </div>
          )}
          {!isMainTab && (
            <>
              {!!trainingId?.length && (
                <div className="admin-training__days--wrap">
                  <div className="admin-training__days-container">
                    <div className="admin-training__days-header-wrap">
                      <div className="admin-training__days-header-name">{translations['dayName'][language]}</div>
                      <div className="admin-training__days-header-days">{translations['duration'][language]}</div>
                      <div className="admin-training__days-header-amount">{translations['exercisesAmount'][language]}</div>
                      <div className="admin-training__days-header-btn-wrap">
                        <div className="admin-training__days-header-copy">{translations['copy'][language]}</div>
                        <div className="admin-training__days-header-edit">{translations['edit'][language]}</div>
                        <div className="admin-training__days-header-delete">{translations['delete'][language]}</div>
                      </div>
                    </div>
                    <div className="admin-training__days-wrap">
                      <DragDropContext onDragEnd={handleDragEnd}>
                        <Droppable droppableId="droppable">
                          {(provided) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              {days.map((item, index) => (
                                <Draggable
                                  key={item._id + index}
                                  draggableId={item._id + index}
                                  index={index}
                                >
                                  {(provided) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      {windowInnerWidth < 640 ? (
                                        <div className="admin-training__mobile-card" onClick={() => handleEdite(item)}>
                                          <div className="admin-training__mobile-card-title--wrap">
                                            <div className="admin-training__mobile-card-title-wrap">
                                              <div className="admin-training__mobile-card-title">{item.name}</div>
                                              <div className="admin-training__mobile-card-days-wrap">
                                                <div className="admin-training__mobile-card-days">
                                                  {item?.exercises?.length} {translations['exercises2'][language]}
                                                </div>
                                                <span>-</span>
                                                <div className="admin-training__mobile-card-days">
                                                  {handleTimeDay(item)} {translations['minutes'][language]}
                                                </div>
                                              </div>
                                            </div>
                                            <button
                                              className="admin-training__card-btn"
                                              onClick={(e) => handleCopt(e, item)}
                                            >
                                              <img
                                                className="admin-training__card-btn-img"
                                                src={copyImgViolet}
                                                alt="img"
                                              />
                                            </button>
                                          </div>
                                          <div className="admin-training__mobile-card-btn-wrap">
                                            <button
                                              className="admin-training__mobile-card-btn admin-training__mobile-card-btn--delete"
                                              onClick={(e) => handleDelete(e, item)}
                                            >
                                              {translations['delete'][language]}
                                            </button>
                                            <button
                                              className="admin-training__mobile-card-btn"
                                              onClick={() => handleEdite(item)}
                                            >
                                              {translations['edit'][language]}
                                            </button>
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="admin-training__days-card" onClick={() => handleEdite(item)}>
                                          <div className="admin-training__days-card-num-wrap">
                                            <img
                                              className="admin-training__days-card-move-img"
                                              src={moveUpImg2}
                                              alt="img"
                                            />
                                            <span className="admin-training__days-card-num">{index + 1}</span>
                                          </div>
                                          <div className="admin-training__days-title">{item.name}</div>
                                          <div className="admin-training__days-card-days">
                                            {handleTimeDay(item)} {translations['minutes'][language]}
                                          </div>
                                          <div className="admin-training__days-card-amount">
                                            {item?.exercises?.length} {translations['exercises2'][language]}
                                          </div>
                                          <div className="admin-training__days-card-btn-wrap">
                                            <button
                                              className="admin-training__days-card-btn"
                                              onClick={(e) => handleCopt(e, item)}
                                            >
                                              <img
                                                className="admin-training__days-card-btn-img"
                                                src={copyImgViolet}
                                                alt="img"
                                              />
                                            </button>
                                            <button
                                              className="admin-training__days-card-btn"
                                              onClick={() => handleEdite(item)}
                                            >
                                              <img
                                                className="admin-training__days-card-btn-img"
                                                src={editViolet}
                                                alt="img"
                                              />
                                            </button>
                                            <button
                                              className={`admin-training__days-card-btn ${days?.length <= 1 ? 'disabledContainer' : ''}`}
                                              onClick={(e) => handleDelete(e, item)}
                                            >
                                              <img
                                                className="admin-training__days-card-btn-img"
                                                src={deleteImgViolet}
                                                alt="img"
                                              />
                                            </button>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        <div className="admin-training__add-btn-wrap">
          {!!trainingId?.length ? (
            <div className="admin-training__add-btn-new-wrap">
              {!isMainTab && (
                <button
                  className="mainBtnDark"
                  onClick={handleUpdateAddDay}
                >
                  + {translations['addDay2'][language]}
                </button>
              )}
              {trainingId === NEW ? (
                <button
                  className="mainBtnDark"
                  disabled={!newImage?.length && !image?.length && !imageLink?.length && !planName?.length && !Boolean(price)}
                  onClick={handleCreate}
                >
                  {translations['create'][language]}
                </button>
              ) : (
                <button
                  className={!isMainTab ? `mainBtnWhite` : 'mainBtnDark'}
                  disabled={!newImage?.length && !image?.length && !imageLink?.length && !planName?.length && !Boolean(price)}
                  onClick={handleUpdateTraining}
                >
                  {translations['save'][language]}
                </button>
              )}
            </div>
          ) : (
            <button
              className="mainBtnDark"
              disabled={!newImage?.length && !image?.length && !imageLink?.length}
              onClick={handleAddDay}
            >
              {translations['next'][language]}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default AdminTrainingView;
