import './AdminTrainingsView.css';
import { NavLink, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import editViolet from '../../assets/editViolet.svg';
import helpIcon from '../../assets/helpIcon.svg';
import qrcodeViolet from '../../assets/qrcodeViolet.svg';
import deleteImgViolet from '../../assets/deleteImgViolet.svg';
import QRCode from 'qrcode.react';
import { useEffect, useRef, useState } from 'react';
import ModalWindow from '../../components/ModalWindow/ModalWindow';
import DoubleButtons from '../../components/DoubleButtons/DoubleButtons';
import { useSelector } from 'react-redux';
import noPhotos from '../../assets/noPhotos.svg';
import { pageMeta } from '../../pageMeta';
import PageMeta from '../../components/PageMeta/PageMeta';
import { translations } from '../../localization';
import { setIsModalStripeRegistration, setTrainingObj, setTrainings } from '../../store/userSlice';
import { fetchRequest, handleGetAllTrainings, handleGetInCome, logIssue } from '../../helpers/Utils';
import { useDispatch } from 'react-redux';
import PreloaderCustom from '../../components/PreloaderCustom/PreloaderCustom';
import { setShowMessageObj } from '../../store/homeSlice';
import InfiniteScroll from 'react-infinite-scroller';
import notTreningImg from '../../assets/notTreningImg.svg';
import LoadingHasMore from '../../components/LoadingHasMore/LoadingHasMore';
import { WARNING } from '../../helpers/Config';

function AdminTrainingsView() {
  const [searchParams, setSearchParams] = useSearchParams();
  const newSearchParams = new URLSearchParams(searchParams);
  const searchValueSearchParams = JSON.parse(searchParams.get('searchValue'));
  const windowInnerWidth = useSelector((state) => state.userSlice.windowInnerWidth);
  const language = useSelector((state) => state.userSlice.language);
  const trainings = useSelector((state) => state.userSlice.trainings);
  const coach = useSelector((state) => state.userSlice.coach);
  const [open, setOpen] = useState(false);
  const [urlQR, setUrlQR] = useState('');
  const [scanTrening, setScanTrening] = useState({});
  const [isModalDelete, setIsModalDelete] = useState(false);
  const [isPreloader, setIsPreloader] = useState(true);
  const [isLoadingHasMore, setIsLoadingHasMore] = useState(false);
  const [deleteTrening, setDeleteTrening] = useState({});
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [inCome, setInCome] = useState(0);
  const [isHasMore, setIsHasMore] = useState(false);
  const [isNotFirstRender, setIsNotFirstRender] = useState(false);
  const [timerId, setTimerId] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const scrollParentRef = useRef(null);
  const { coachId } = useParams();
  let token = localStorage.getItem('token-admin');

  useEffect(() => {
    setIsNotFirstRender(true);
    handleGetTrainings(0, searchValueSearchParams?.length ? searchValueSearchParams : '');
    handleGetInCome(token, (res) => setInCome(res));
    dispatch(setTrainingObj({}));
  }, []);

  useEffect(() => {
    if (isNotFirstRender) {
      createTimer();
    }
  }, [searchValueSearchParams]);

  const createTimer = () =>  {
    function startTimer() {
      if (timerId !== null) {
          clearTimeout(timerId);
      }

      setTimerId(setTimeout(() => {
        console.log('timerId');
        handleGetTrainings(0);
        setPage(0);
        setTimerId(null);
      }, 300));
    }

    return startTimer();
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenQR = (e, obj) => {
    e.preventDefault();
    e.stopPropagation();
    if (coach.stripe_activated) {
      setOpen(true);
      setUrlQR(obj._id);
      setScanTrening(obj);
    } else {
      dispatch(setIsModalStripeRegistration(true));
    }
  };

  const handleEdite = (e, obj) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(setTrainingObj(obj));
    navigate(`/auth/${coachId}/trainings/${obj._id}`);
  };

  const handleDelete = (e, obj) => {
    e.preventDefault();
    e.stopPropagation();
    setIsModalDelete(true);
    setDeleteTrening(obj);
  };

  const handleIsDeleteTraining = (boolean) => {
    if (boolean) {
      setIsPreloader(true);
      let data = {};
      fetchRequest('DELETE', `/trainings/${deleteTrening._id}?token=${token}`, data, () => dispatch(setShowMessageObj({ open: true, status: 'error', message: 'An error occurred' }))).then((res) => {
        if (res?.success && res.data) {
          dispatch(setTrainings(trainings.filter((el) => el._id !== deleteTrening._id)));
          dispatch(setShowMessageObj({ open: true, status: 'success', message: translations['dataUpdated'][language] }));
        } else {
          logIssue(WARNING, "handleIsDeleteTraining AdminTrainingsView", res);
        }
        setIsPreloader(false);
      });
    }
    setIsModalDelete(false);
    setDeleteTrening({});
  };

  const handleLoadMore = () => {
    setIsHasMore(false);
    if (isHasMore) {
      handleGetTrainings(page + 1);
      setPage(page + 1);
    }
  };

  const handleSetTrainings = (res, newPage) => {
    if (res?.success && res?.data) {
      const newData = res.data?.items || [];
      const updatedData = newPage ? [...trainings, ...newData] : newData;
      dispatch(setTrainings(updatedData));
      setIsHasMore(res?.data?.is_has_more ? true : false);
      setTotalCount(res.data?.count || 0);
    }
    setIsPreloader(false);
    setIsLoadingHasMore(false);
  };

  const handleGetTrainings = (newPage = null, startSearchValue = null) => {
    if (newPage === null || newPage === 0) {
      setIsPreloader(true);
    } else {
      setIsLoadingHasMore(true);
    }
    setIsHasMore(false);
    handleGetAllTrainings(token, newPage, page, startSearchValue, searchValueSearchParams, false, handleSetTrainings);
  };

  return (
    <div className="admin-trainings-wrap">
      <PageMeta {...pageMeta['AdminTrainingsView']} />
      {isPreloader && <PreloaderCustom />}
      {open && (
        <ModalWindow
          title={''}
          handleClick={handleClose}
          notBtn={true}
        >
          {!!urlQR?.length && (
            <div className="admin-trainings__dialogform-qr-wrap">
              <QRCode
                value={urlQR}
                size={260}
              />
            </div>
          )}
          <div className="admin-trainings__dialogform-name-wrap">
            <div className="admin-trainings__dialogform-name">
              {translations['training'][language]}: {scanTrening?.name}
            </div>
            <div className="admin-trainings__dialogform-sub-title">
              {scanTrening?.days?.length} {translations['days'][language]}
            </div>
          </div>
          <div className="admin-trainings__dialogform-btn-wrap">
            <button
              className="mainBtnDark admin-trainings__dialogform-btn--qr"
              onClick={handleClose}
            >
              {translations['close'][language]}
            </button>
          </div>
        </ModalWindow>
      )}
      {isModalDelete && (
        <ModalWindow
          title={''}
          handleClick={handleIsDeleteTraining}
        >
          <div className="admin-trainings__dialogform-delete-wrap">
            <img
              className="admin-trainings__dialogform-delete-img"
              src={helpIcon}
              alt="img"
            />
            <div className="admin-trainings__dialogform-delete-text">
              <div>{translations['deleteTraining'][language]}</div>
              <div className="admin-trainings__dialogform-delete-text-name">{deleteTrening.name}</div>
            </div>
          </div>
        </ModalWindow>
      )}
      <div className="admin-trainings">
        <div className="admin-trainings__main-wrap">
          <div className="admin-trainings__btn--wrap">
            <div className="admin-trainings__count-wrap">
              <div className="admin-trainings__count">{totalCount}</div>
              <div className="admin-trainings__count-text">{translations['activeTrainings'][language]}</div>
            </div>
            <div className="admin-trainings__btn-wrap">
              <DoubleButtons
                isActive={location.pathname === `/auth/${coachId}/trainings`}
                leftBtnText={translations['trainings'][language]}
                rightBtnText={translations['exercises'][language]}
                onClickLeftBtn={() => navigate(`/auth/${coachId}/trainings`)}
                onClickRightBtn={() => navigate(`/auth/${coachId}/plans`)}
              />
            </div>
            <div className="admin-trainings__price-wrap">
              <div className="admin-trainings__price">${inCome}</div>
              <div className="admin-trainings__price-text">{translations['yourIncome'][language]}</div>
            </div>
          </div>

          <div className="admin-trainings__users--wrap">
            {!!trainings?.length ? (
              <div
                className="admin-trainings__users-wrap"
                ref={scrollParentRef}
              >
                <InfiniteScroll
                  pageStart={0}
                  useWindow={false}
                  getScrollParent={() => scrollParentRef.current}
                  loadMore={handleLoadMore}
                  hasMore={isHasMore}
                  className="admin-trainings__users"
                >
                  <div className="admin-trainings__users-header-wrap">
                    <div className="admin-trainings__users-header-name">{translations['name'][language]}</div>
                    <div className="admin-trainings__users-header-days">{translations['duration'][language]}</div>
                    <div className="admin-trainings__users-header-users">{translations['usersAmount'][language]}</div>
                    <div className="admin-trainings__users-header-qr-wrap">
                      <div className="admin-trainings__users-header-qr">QR</div>
                      <div className="admin-trainings__users-header-edit">{translations['edit'][language]}</div>
                      <div className="admin-trainings__users-header-delete">{translations['delete'][language]}</div>
                    </div>
                  </div>
                  {trainings.map((el, i) => (
                    <NavLink
                      className="admin-trainings__card"
                      to={`${el._id}`}
                      key={el._id}
                    >
                      {windowInnerWidth < 640 ? (
                        <>
                          <div className="admin-trainings__mobile-card-img-wrap">
                            <img
                              className="admin-trainings__mobile-card-img"
                              src={el?.image?.length ? el?.image : noPhotos}
                              alt="img"
                            />
                            {/* <NavLink className='admin-trainings__mobile-card-btn-forward' to={`/training/${el._id}?language=${language}`}>
                                                                        <img className='admin-trainings__card-arrow' src={forwardArrow} alt='img'/>
                                                                    </NavLink> */}
                            <button
                              className="admin-trainings__mobile-card-btn"
                              onClick={(e) => handleOpenQR(e, el)}
                            >
                              <img
                                className="admin-trainings__mobile-card-btn-img"
                                src={qrcodeViolet}
                                alt="img"
                              />
                            </button>
                          </div>
                          <div className="admin-trainings__mobile-card-text">
                            <div className="admin-trainings__mobile-card-text-title">{el?.name}</div>
                            <div className="admin-trainings__mobile-card-text-users">
                              {el.users_amount} {translations['usersUseIt'][language]}
                            </div>
                            <div className="admin-trainings__mobile-card-text-btn-wrap">
                              <div className="admin-trainings__mobile-card-text-days">
                                {el?.days?.length} {translations['days'][language]}
                              </div>
                              <div className="admin-trainings__mobile-card-text-btn--wrap">
                                <button
                                  className="admin-trainings__mobile-card-text-btn admin-trainings__mobile-card-text-btn--delete"
                                  onClick={(e) => handleDelete(e, el)}
                                >
                                  {translations['delete'][language]}
                                </button>
                                <button
                                  className="admin-trainings__mobile-card-text-btn admin-trainings__mobile-card-text-btn--edit"
                                  onClick={(e) => handleEdite(e, el)}
                                >
                                  {translations['edit'][language]}
                                </button>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="admin-trainings__title-wrap">
                            <div className="admin-trainings__card-num">{i + 1}</div>
                            <img
                              className="admin-trainings__card-img"
                              src={el?.image?.length ? el?.image : noPhotos}
                              alt="img"
                            />
                            <div className="admin-trainings__title">{el.name}</div>
                            {/* <NavLink to={`/training/${el._id}?language=${language}`} target="_blank" >
                                                                        <img className='admin-trainings__card-arrow' src={forwardArrow} alt='img'/>
                                                                    </NavLink> */}
                          </div>
                          <div className="admin-trainings__card-days">
                            {el?.days?.length} {translations['days'][language]}
                          </div>
                          <div className="admin-trainings__card-users">
                            {el.users_amount ? el.users_amount : 0} {translations['usersUseIt'][language]}
                          </div>
                          <div className="admin-trainings__card-btn-wrap">
                            <button
                              className="admin-trainings__card-btn"
                              onClick={(e) => handleOpenQR(e, el)}
                            >
                              <img
                                className="admin-trainings__card-btn-img"
                                src={qrcodeViolet}
                                alt="img"
                              />
                            </button>
                            <button
                              className="admin-trainings__card-btn"
                              onClick={(e) => handleEdite(e, el)}
                            >
                              <img
                                className="admin-trainings__card-btn-img"
                                src={editViolet}
                                alt="img"
                              />
                            </button>
                            <button
                              className="admin-trainings__card-btn"
                              onClick={(e) => handleDelete(e, el)}
                            >
                              <img
                                className="admin-trainings__card-btn-img"
                                src={deleteImgViolet}
                                alt="img"
                              />
                            </button>
                          </div>
                        </>
                      )}
                    </NavLink>
                  ))}
                  {
                    isLoadingHasMore && <LoadingHasMore />
                  }
                </InfiniteScroll>
              </div>
            ) : (
              <>
                {!!searchValueSearchParams?.length ? (
                  <div className="admin-all-plans__empty-wrap">{translations['noSearchResult'][language]}</div>
                ) : (
                  <div className="admin-users__empty-img-wrap">
                    <img
                      className="admin-trainings__empty-img"
                      src={notTreningImg}
                      alt="img"
                    />
                    <div className="admin-all-plans__empty-wrap">{translations['trainingsListEmpty'][language]}</div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <div className="admin-trainings__add-btn-wrap">
          <NavLink
            className="mainBtnDark"
            to={`/auth/${coachId}/trainings/create`}
            onClick={() => dispatch(setTrainingObj({}))}
          >
            + {translations['addTraining'][language]}
          </NavLink>
        </div>
      </div>
    </div>
  );
}

export default AdminTrainingsView;
