import './CoachingPlanView.css';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ModalWindow from '../../components/ModalWindow/ModalWindow';
import { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import arrowBorder from '../../assets/arrowBorder.svg';
import { pageMeta } from '../../pageMeta';
import PageMeta from '../../components/PageMeta/PageMeta';
import { translations } from '../../localization';
import { useSelector } from 'react-redux';
import { setUserExercise, setUserTraining } from '../../store/homeSlice';
import { fetchGetData } from '../../helpers/Utils';
import { useDispatch } from 'react-redux';
import PreloaderCustom from '../../components/PreloaderCustom/PreloaderCustom';
import noPhotos from '../../assets/noPhotos.svg';
import playImg from '../../assets/playImg.svg';
import TimeProgress from '../../components/TimeProgress/TimeProgress';
import CoachingRest from '../../components/CoachingRest/CoachingRest';

function CoachingPlanView() {
  const language = useSelector((state) => state.homeSlice.language);
  const userExercise = useSelector((state) => state.homeSlice.userExercise);
  const userTraining = useSelector((state) => state.homeSlice.userTraining);
  const user = useSelector((state) => state.homeSlice.user);
  const [open, setOpen] = useState(false);
  const [isPreloader, setIsPreloader] = useState(false);
  const [isPreview, setIsPreview] = useState(true);
  const [isNotFirstRender, setIsNotFirstRender] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { coachingId } = useParams();
  const { coachingDayId } = useParams();
  const { coachingPlanId } = useParams();
  const { userId } = useParams();
  const playerRef = useRef(null);
  let token = localStorage.getItem('token-user');

  useEffect(() => {
    setIsNotFirstRender(true);
    if (!userExercise?._id?.length || userExercise?._id !== coachingPlanId) {
      handleGetExercise();
    } else {
      setIsPreview(!userExercise.preview?.length ? false : true);
    }
    if (!userTraining?._id?.length) {
      handleGetTraining();
    }
  }, []);

  useEffect(() => {
    if (isNotFirstRender && userExercise?._id !== coachingPlanId) {
      handleGetExercise();
    }
  }, [coachingPlanId]);

  useEffect(() => {
    if (coachingPlanId?.length && userTraining?._id?.length) {
      const array = userTraining.days.find((item) => item._id === coachingDayId).exercises;
      if (array.length) {
        const activeExerciseIndex = array.findIndex((item) => item._id === userTraining.active_exercise_id);
        const coachingPlanIndex = array.findIndex((item) => item._id === coachingPlanId);
        if (activeExerciseIndex !== -1 && coachingPlanIndex !== -1 && activeExerciseIndex !== coachingPlanIndex && activeExerciseIndex > coachingPlanIndex) {
          navigate(`/user/${user._id}/${coachingId}/${coachingDayId}`);
        }
      }
    }
  }, [location]);

  useEffect(() => {
    const interval = setInterval(() => {
      const videoElement = playerRef.current?.getInternalPlayer();
      if (videoElement) {
        videoElement.setAttribute('disablePictureInPicture', true); 
        videoElement.setAttribute('controlsList', 'nodownload'); 
        clearInterval(interval); 
      }
    }, 100); 
    return () => clearInterval(interval);
  }, [playerRef.current]);

  const handleGetExercise = () => {
    setIsPreloader(true);
    fetchGetData(`/exercises/${coachingPlanId}?token=${token}`).then((res) => {
      if (res?.success && res?.data) {
        dispatch(setUserExercise(res.data));
        setIsPreview(!res.data.preview?.length ? false : true);
      } else {
        navigate(`/user/${userId}/not-found`);
      }
      setIsPreloader(false);
    });
  };

  const handleIsNextStep = (boolean) => {
    if (boolean) {
    }
    setOpen(false);
  };

  const handleGetTraining = () => {
    fetchGetData(`/trainings/${coachingId}?token=${token}`).then((res) => {
      if (res?.success && res?.data) {
        dispatch(setUserTraining(res.data));
      } else {
        navigate(`/user/${userId}/not-found`);
      }
    });
  };

  return (
    <div className="coaching-plan-view-wrap">
      <PageMeta {...pageMeta['CoachingPlanView']} />
      {isPreloader && (
        <PreloaderCustom
          newStyle={{ height: `100vh`, left: `0`, top: '0' }}
          isNewStyleLoader={true}
        />
      )}
      {open && (
        <ModalWindow
          title={translations['areYouSure'][language]}
          handleClick={handleIsNextStep}
          leftBtn={translations['cancel'][language]}
          rightBtn={translations['next'][language]}
        />
      )}

      <div className="coaching-plan-view container">
        <button
          className="coaching-plan-view__btn-back"
          onClick={() => navigate(`/user/${user?._id}/${coachingId}/${coachingDayId}`)}
        >
          <img
            className="coaching-plan-view__btn-back-img"
            src={arrowBorder}
            alt="img"
          />
        </button>
        {!!userExercise._id?.length && !!userExercise.name?.length && (
          <>
            {!!userExercise?.video?.length ? (
              <div className="coaching-plan-view__video-wrap">
                {!!userExercise.preview?.length && isPreview && (
                  <div
                    className="coaching-plan-view__video-img-wrap"
                    onClick={() => setIsPreview(false)}
                  >
                    <img
                      className="coaching-plan-view__video-img"
                      src={userExercise.preview}
                      alt="img"
                    />
                    <img
                      className="coaching-plan-view__video-img-play"
                      src={playImg}
                      alt="img"
                    />
                  </div>
                )}
                {!isPreview && (
                  <div
                    onContextMenu={(e) => e.preventDefault()} // Блокування контекстного меню
                    style={{ width: '100%', height: '100%' }}
                  >
                    <ReactPlayer
                      ref={playerRef}
                      className="coaching-plan-view__video"
                      url={userExercise?.video}
                      controls={true}
                      loop={true}
                      config={{
                        youtube: {
                          playerVars: { modestbranding: 1, rel: 0, showinfo: 0 },
                          embedOptions: { host: 'https://www.youtube.com', embedPath: '/embed/' },
                          preload: false,
                          xhrContext: { headers: { 'Access-Control-Allow-Origin': '*' } },
                        },
                      }}
                      preload="metadata"
                      playing={!!userExercise.preview?.length ? true : false}
                    />
                  </div>
                )}
              </div>
            ) : (
              <img
                className="coaching-plan-view__video-img--no-photos"
                src={noPhotos}
                alt="img"
              />
            )}
            <div className="coaching-plan-view__info">{!!userExercise._id?.length && <TimeProgress />}</div>
          </>
        )}
        {!!userExercise._id?.length && !userExercise.name?.length && <CoachingRest />}
      </div>
    </div>
  );
}

export default CoachingPlanView;
