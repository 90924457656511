import './CoachingDayView.css';
import CoachingPlanCard from '../../components/CoachingPlanCard/CoachingPlanCard';
import { useNavigate, useParams } from 'react-router-dom';
import { pageMeta } from '../../pageMeta';
import PageMeta from '../../components/PageMeta/PageMeta';
import { useSelector } from 'react-redux';
import { translations } from '../../localization';
import { useEffect, useState } from 'react';
import { fetchGetData } from '../../helpers/Utils';
import { setUserTraining } from '../../store/homeSlice';
import { useDispatch } from 'react-redux';
import PreloaderCustom from '../../components/PreloaderCustom/PreloaderCustom';
import ModalWindow from '../../components/ModalWindow/ModalWindow';

function CoachingDayView() {
  const language = useSelector((state) => state.homeSlice.language);
  const userTraining = useSelector((state) => state.homeSlice.userTraining);
  const [isPreloader, setIsPreloader] = useState(false);
  const [isModalRestart, setIsModalRestart] = useState(false);
  const [isModalFinish, setIsModalFinish] = useState(false);
  const [exercises, setExercises] = useState([]);
  const [startTimeDay, setStartTimeDay] = useState(null);
  const { coachingId } = useParams();
  const { coachingDayId } = useParams();
  const { userId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let token = localStorage.getItem('token-user');

  useEffect(() => {
    if (!userTraining?._id?.length) {
      handleGetTraining();
    } else {
      let resDay = userTraining.days.find((el) => el._id === coachingDayId);
      let arr = resDay?.exercises;
      setExercises(arr?.length ? [...arr] : []);
      setStartTimeDay(resDay?.start_time);
    }
  }, []);

  const handleCheckTime = () => {
    let res = false;

    if (startTimeDay) {
      const startDate = new Date(startTimeDay);
      const currentDate = new Date();
      const timeDifferenceInMillis = currentDate - startDate;
      const hoursDifference = timeDifferenceInMillis / (1000 * 60 * 60);
      if (hoursDifference >= 10) {
        res = true;
      }
    }

    return res;
  };

  const handleRestartDay = (boolean) => {
    if (boolean) {
    }

    setIsModalRestart(false);
  };

  const handleFinishDay = (boolean) => {
    if (boolean) {
    }

    setIsModalFinish(false);
  };

  const handleGetTraining = () => {
    setIsPreloader(true);
    fetchGetData(`/trainings/${coachingId}?token=${token}`).then((res) => {
      if (res?.success && res?.data) {
        dispatch(setUserTraining(res.data));
        let resDay = res.data?.days?.find((el) => el._id === coachingDayId);
        let arr = resDay?.exercises;
        setExercises(arr?.length ? [...arr] : []);
        setStartTimeDay(resDay?.start_time);
      } else {
        navigate(`/user/${userId}/not-found`);
      }
      setIsPreloader(false);
    });
  };

  return (
    <div className="coaching-day-view-wrap">
      <PageMeta {...pageMeta['CoachingDayView']} />
      {isPreloader && (
        <PreloaderCustom
          newStyle={{ height: `100vh`, left: `0`, top: '0' }}
          isNewStyleLoader={true}
        />
      )}
      {isModalRestart && (
        <ModalWindow
          title={translations['restartDay'][language]}
          handleClick={handleRestartDay}
        ></ModalWindow>
      )}
      {isModalFinish && (
        <ModalWindow
          title={translations['finishDay'][language]}
          handleClick={handleFinishDay}
        ></ModalWindow>
      )}

      <div className="coaching-day-view container">
        <div className="coaching-day-view__info-wrap">
          <div className="coaching-day-view__info-title-wrap">
            <div className="coaching-day-view__info-title">{translations['exercises'][language]}</div>
          </div>
        </div>
        {startTimeDay && handleCheckTime() ? (
          <div className="coaching-day-view__finish-wrap">
            <div className="coaching-day-view__finish-title">{translations['lostTraining'][language]}</div>
            <div className="coaching-day-view__finish-sub-title">{translations['trainingNext'][language]}</div>
            <div className="coaching-day-view__btn-wrap">
              <button
                className="mainBtnWhite mainBtn--minWhidth"
                onClick={() => setIsModalFinish(true)}
              >
                {translations['finishDayBtn'][language]}
              </button>
              <button
                className="mainBtnDark mainBtn--minWhidth"
                onClick={() => setIsModalRestart(true)}
              >
                {translations['restartDayBtn'][language]}
              </button>
            </div>
          </div>
        ) : (
          <div className="coaching-day-view__day-wrap">
            {!!exercises?.length &&
              exercises.map((el, index) => (
                <CoachingPlanCard
                  key={`${el?._id}-${index}`}
                  coachingExercise={el}
                  index={index}
                />
              ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default CoachingDayView;
