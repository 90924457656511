import './AdminTrainingDayView.css';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import deleteImgViolet from '../../assets/deleteImgViolet.svg';
import editViolet from '../../assets/editViolet.svg';
import { useEffect, useState } from 'react';
import TextInput from '../../components/TextInput/TextInput';
import SelectExercise from '../../components/SelectExercise/SelectExercise';
import { pageMeta } from '../../pageMeta';
import PageMeta from '../../components/PageMeta/PageMeta';
import { useSelector } from 'react-redux';
import { translations } from '../../localization';
import { setBackToCreateTrening, setExercises, setTrainingObj } from '../../store/userSlice';
import { fetchGetData, handleGetAllExercises } from '../../helpers/Utils';
import PreloaderCustom from '../../components/PreloaderCustom/PreloaderCustom';
import { useDispatch } from 'react-redux';
import copyImgViolet from '../../assets/copyImgViolet.svg';
import menuButton from '../../assets/menuButton.svg';
import { LAST, NEW } from '../../helpers/Config';
import { setShowMessageObj } from '../../store/homeSlice';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Popover } from '@mui/material';

function AdminTrainingDayView() {
  const language = useSelector((state) => state.userSlice.language);
  const exercises = useSelector((state) => state.userSlice.exercises);
  const trainingObj = useSelector((state) => state.userSlice.trainingObj);
  const windowInnerWidth = useSelector((state) => state.userSlice.windowInnerWidth);
  const [isPreloader, setIsPreloader] = useState(true);
  const [isNotFirstRender, setIsNotFirstRender] = useState(false);
  const [selectExerciseArr, setSelectExerciseArr] = useState([]);
  const [anchorEls, setAnchorEls] = useState(Array(selectExerciseArr.length).fill(null));
  const { trainingId } = useParams();
  const { dayId } = useParams();
  const { coachId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [dayName, setDayName] = useState(`Day ${dayId}`);
  let token = localStorage.getItem('token-admin');

  useEffect(() => {
    handleGetExercises();

    if (!trainingId?.length && !trainingObj?.name?.length) {
      navigate(`/auth/${coachId}/trainings/create`);
      dispatch(setShowMessageObj({ open: true, status: 'error', message: translations['mandatoryFields2'][language] }));
    }

    if (!!trainingId?.length && !trainingObj?.name?.length) {
      handleGetTraining();
    }

    console.log('trainingObj', trainingObj);
    if (trainingObj?._id?.length) {
      handleSetInput(trainingObj);
    }

    const queryParams = new URLSearchParams(location.search);
    const paramIndex = queryParams.get('index');
    const exercisesCount = localStorage.getItem('exercisesCount');
    if (paramIndex?.length && exercisesCount?.length && parseInt(exercises?.length) > parseInt(exercisesCount)) {
      handleSetNewExercise(paramIndex);
    }
    if (exercisesCount?.length) {
      localStorage.removeItem('exercisesCount');
    }
  }, []);

  useEffect(() => {
    if (trainingObj.days?.length) {
      handleSetInput(trainingObj);
    }
  }, [dayId]);

  useEffect(() => {
    if (isNotFirstRender) {
      dispatch(
        setTrainingObj({
          ...trainingObj,
          days: trainingObj.days?.length ? [...trainingObj.days.map((el) => (el._id === dayId ? { ...el, name: dayName } : el))] : [{ _id: dayId, order: dayId, name: dayName, exercises: [] }],
        }),
      );
    }
  }, [dayName]);

  useEffect(() => {
    if (trainingObj?.name?.length) {
      handleReturnSelectExercise(trainingObj);
    }
  }, [trainingObj]);

  const handleSetNewExercise = (strIndex) => {
    if (trainingObj.days?.length) {
      let upDay = trainingObj.days?.find((el) => el?._id === dayId);
      let newExercise = [];
      if (upDay?.exercises?.length) {
        if (strIndex !== LAST) {
          newExercise = [...upDay.exercises.map((el, i) => (i == strIndex ? exercises[0] : el))];
        } else {
          newExercise = [...upDay.exercises, exercises[0]];
        }
      } else {
        newExercise = [exercises[0]];
      }
      if (upDay) {
        dispatch(
          setTrainingObj({
            ...trainingObj,
            days: [...trainingObj.days.map((el) => (el._id === dayId ? { ...el, exercises: [...newExercise] } : el))],
          }),
        );
      } else {
        dispatch(
          setTrainingObj({
            ...trainingObj,
            days: [...trainingObj.days, { _id: dayId, order: dayId, name: dayName, exercises: [exercises[0]] }],
          }),
        );
      }
    } else {
      dispatch(
        setTrainingObj({
          ...trainingObj,
          days: [{ _id: dayId, order: dayId, name: dayName, exercises: [exercises[0]] }],
        }),
      );
    }
  };

  const handleSetInput = (obj) => {
    let res = obj?.days.find((el) => el._id === dayId);
    console.log('res', res);
    setDayName(res?.name?.length ? res.name : `${translations['day'][language]} ${dayId}`);
    handleReturnSelectExercise(obj);
  };

  const handleDuplicateDay = () => {
    let oldDay = trainingObj.days?.find((el) => el._id === dayId);
    dispatch(
      setTrainingObj({
        ...trainingObj,
        days: [...trainingObj.days, { _id: (trainingObj.days?.length + 1).toString(), order: (trainingObj.days?.length + 1).toString(), name: `${translations['day'][language]} ${trainingObj.days?.length + 1}`, exercises: [...oldDay.exercises] }],
      }),
    );
    if (trainingId?.length) {
      navigate(`/auth/${coachId}/trainings/${trainingId}/${trainingObj.days?.length + 1}`);
    } else {
      navigate(`/auth/${coachId}/trainings/create/${trainingObj.days?.length + 1}`);
    }
    dispatch(setShowMessageObj({ open: true, status: 'success', message: translations['dayWasAdded'][language] }));
  };

  const handleNexDay = () => {
    if (handleCheckExercise()) {
      dispatch(setShowMessageObj({ open: true, status: 'error', message: translations['selectExercise'][language] }));
      return;
    }
    navigate(`/auth/${coachId}/trainings/create/${Number(dayId) + 1}`);
  };

  const handleEditeExercise = (id, index = null) => {
    navigate(`/auth/${coachId}/plans/${id}`);
    dispatch(setBackToCreateTrening(location.pathname));
    if (index || index === 0) {
      handlePopoverClose(index);
    }
  };

  const handleDeleteExercise = (index) => {
    let oldDay = trainingObj.days?.find((el) => el._id === dayId);
    let newExercises = oldDay.exercises?.length ? oldDay.exercises?.filter((el, i) => i !== index) : [];
    dispatch(
      setTrainingObj({
        ...trainingObj,
        days: [...trainingObj.days.map((el) => (el._id === dayId ? { ...el, exercises: [...newExercises] } : el))],
      }),
    );
    handlePopoverClose(index);
  };

  const handleCheckExercise = () => {
    let res = true;
    if (trainingObj.days?.length) {
      let day = trainingObj.days?.find((el) => el._id === dayId);
      if (day?.exercises?.length) {
        res = false;
      }
    }
    return res;
  };

  const handlePreviewTraining = () => {
    if (handleCheckExercise()) {
      dispatch(setShowMessageObj({ open: true, status: 'error', message: translations['selectExercise'][language] }));
      return;
    }
    navigate(`/auth/${coachId}/trainings/new`);
  };

  const handleSaveTraining = () => {
    if (handleCheckExercise()) {
      dispatch(setShowMessageObj({ open: true, status: 'error', message: translations['selectExercise'][language] }));
      return;
    }
    if (trainingObj.days?.length && trainingObj.days?.find((el) => el._id === dayId)?.exercises?.length) {
      navigate(`/auth/${coachId}/trainings/${trainingId}`);
    }
  };

  const handleGetTraining = () => {
    setIsPreloader(true);
    fetchGetData(`/trainings/${trainingId}?token=${token}`).then((res) => {
      if (res?.success && res?.data) {
        // dispatch(setTrainingObj(res.data));
        dispatch(setTrainingObj({
          ...res?.data, 
          days: res?.data?.days?.map((el) => ({ 
            ...el, 
            exercises: el.exercises.filter((exercise) => exercise?._id?.length) 
          }))
        }));
        handleSetInput(res.data);
      } else {
        navigate(`/auth/${coachId}/not-found`);
      }
      setIsPreloader(false);
    });
  };

  const handleSetExercises = (res) => {
    if (res?.success && res?.data) {
      dispatch(setExercises(res.data?.items?.length ? res.data?.items : []));
    } else {
      navigate(`/auth/${coachId}/not-found`);
    }
    setIsPreloader(false);
    setIsNotFirstRender(true);
  };

  const handlePopoverOpen = (event, index) => {
    const newAnchorEls = [...anchorEls];
    newAnchorEls[index] = event.currentTarget;
    setAnchorEls(newAnchorEls);
  };

  const handlePopoverClose = (index) => {
    const newAnchorEls = [...anchorEls];
    newAnchorEls[index] = null;
    setAnchorEls(newAnchorEls);
  };

  const handleGetExercises = () => {
    setIsPreloader(true);
    handleGetAllExercises(token, 0, 0, '', '', true, handleSetExercises);
  };

  const handleReturnSelectExercise = (obj) => {
    let arr = obj.days?.length && obj.days.find((el) => el._id === dayId)?.exercises?.length ? [...obj.days.find((el) => el._id === dayId)?.exercises] : [];
    if (!!obj.name?.length && !!obj.days?.length) {
      setSelectExerciseArr([...arr]);
    }
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedItems = selectExerciseArr;
    const [removed] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, removed);

    setSelectExerciseArr(reorderedItems);
    dispatch(
      setTrainingObj({
        ...trainingObj,
        days: trainingObj.days.map((el) => {
          return el._id === dayId ? { ...el, exercises: [...reorderedItems] } : el;
        }),
      }),
    );
  };

  return (
    <div className="admin-training-day-wrap">
      <PageMeta {...pageMeta['AdminTrainingDayView']} />
      {isPreloader && <PreloaderCustom />}
      <div className="admin-training-day">
        <div className="admin-training-day__input-wrap">
          <div className="admin-training-day__input--wrap">
            <div className="admin-training-day__input-name-wrap">
              <TextInput
                setValue={setDayName}
                value={dayName}
                label={translations['dayName'][language]}
                newStyle={{ maxWidth: '100%' }}
              />
              <button
                className={`admin-training-day__btn-duplicate ${handleCheckExercise() ? 'disabledContainer' : ''}`}
                onClick={() => handleDuplicateDay()}
              >
                <span className="admin-training-day__btn-duplicate-text">{translations['duplicateThisDay'][language]}</span>
                <img
                  className="admin-training-day__btn-duplicate-img"
                  src={copyImgViolet}
                  alt="img"
                />
              </button>
            </div>
            {!!selectExerciseArr?.length && (
              <div className="admin-training-day__exercise-wrap">
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {selectExerciseArr.map((item, index) => (
                          <Draggable
                            key={item?._id + index}
                            draggableId={item?._id + index}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className="admin-training-day__exercise-input--wrap"
                              >
                                {
                                  <div className="admin-training-day__exercise-input-wrap">
                                    <SelectExercise
                                      exercise={item}
                                      index={index}
                                      dayName={dayName}
                                      selectExerciseArr={selectExerciseArr}
                                    />
                                    {!!item?._id?.length && (
                                      <>
                                        {windowInnerWidth < 640 ? (
                                          <>
                                            <button
                                              aria-describedby={`simple-popover-exercise-${item?._id}`}
                                              className="admin-training-day__popover-btn"
                                              onClick={(e) => handlePopoverOpen(e, index)}
                                            >
                                              <img
                                                className="admin-training-day__popover-btn-img"
                                                src={menuButton}
                                                alt="img"
                                              />
                                            </button>
                                            <Popover
                                              className="admin-training-day__popover-wrap"
                                              id={`simple-popover-exercise-${item?._id}`}
                                              open={Boolean(anchorEls[index])}
                                              anchorEl={anchorEls[index]}
                                              onClose={() => handlePopoverClose(index)}
                                              anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'center',
                                              }}
                                            >
                                              <div className="admin-training-day__popover-items">
                                                <button
                                                  className="admin-training-day__exercise-btn-delete"
                                                  onClick={() => handleEditeExercise(item?._id, index)}
                                                >
                                                  <img
                                                    src={editViolet}
                                                    alt="delete"
                                                  />
                                                </button>
                                                <button
                                                  className="admin-training-day__exercise-btn-delete"
                                                  onClick={() => handleDeleteExercise(index)}
                                                >
                                                  <img
                                                    src={deleteImgViolet}
                                                    alt="delete"
                                                  />
                                                </button>
                                              </div>
                                            </Popover>
                                          </>
                                        ) : (
                                          <>
                                            <button
                                              className="admin-training-day__exercise-btn-delete"
                                              onClick={() => handleEditeExercise(item?._id)}
                                            >
                                              <img
                                                src={editViolet}
                                                alt="delete"
                                              />
                                            </button>
                                            <button
                                              className="admin-training-day__exercise-btn-delete"
                                              onClick={() => handleDeleteExercise(index)}
                                            >
                                              <img
                                                src={deleteImgViolet}
                                                alt="delete"
                                              />
                                            </button>
                                          </>
                                        )}
                                      </>
                                    )}
                                  </div>
                                }
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            )}
            <SelectExercise
              exercise={{ name: '', _id: '' }}
              dayName={dayName}
              index={LAST}
            />
          </div>
        </div>
        <div className="admin-training-day__add-btn-wrap">
          {!!trainingId?.length && trainingId !== NEW ? (
            <button
              className={`mainBtnDark ${handleCheckExercise() ? 'opacityContainer-5' : ''}`}
              onClick={handleSaveTraining}
            >
              {translations['save'][language]}
            </button>
          ) : (
            <>
              <button
                className={`mainBtnWhite ${handleCheckExercise() ? 'opacityContainer-5' : ''}`}
                onClick={handlePreviewTraining}
              >
                {translations['finish'][language]}
              </button>
              <button
                className={`mainBtnDark ${handleCheckExercise() ? 'opacityContainer-5' : ''}`}
                onClick={handleNexDay}
              >
                {translations['nextDay'][language]}
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default AdminTrainingDayView;
