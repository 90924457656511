import './Layout.css';
import React, { useEffect, useState } from 'react';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import { useDispatch } from 'react-redux';
import { Outlet } from 'react-router';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { fetchGetData, handleCheckNewEmailTime, handleCheckNewMessages, handleExit, handleUpdateEmail, handleUpdatePassword } from '../helpers/Utils';
import { setIs_app, setIsModalFinished, setIsUnreadMessagesUser, setLanguage, setUser } from '../store/homeSlice';
import finish from '../assets/finish.svg';
import { useSelector } from 'react-redux';
import ModalWindow from '../components/ModalWindow/ModalWindow';
import ConfettiFinish from '../components/ConfettiFinish/ConfettiFinish';
import { translations } from '../localization';
import dotImg from '../assets/dotImg.svg';
import envelopeImage from '../assets/envelopeImage.svg';

function Layout() {
  const [searchParams, setSearchParams] = useSearchParams();
  const newSearchParams = new URLSearchParams(searchParams);
  const tokenSearchParams = newSearchParams.get('token');
  const is_paidSearchParams = newSearchParams.get('is_paid');
  const assigned_idSearchParams = newSearchParams.get('assigned_id');
  const is_passwordSearchParams = newSearchParams.get('is_password');
  const is_emailSearchParams = newSearchParams.get('is_email');
  const finished_daySearchParams = newSearchParams.get('finished_day');
  const finished_trainingSearchParams = newSearchParams.get('finished_training');
  const language = useSelector((state) => state.homeSlice.language);
  const userTrainings = useSelector((state) => state.homeSlice.userTrainings);
  const user = useSelector((state) => state.homeSlice.user);
  const isModalFinished = useSelector((state) => state.homeSlice.isModalFinished);
  const [isPassword, setIsPassword] = useState(false);
  const [isEmail, setIsEmail] = useState(false);
  const [isPaid, setIsPaid] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  let token = localStorage.getItem('token-user');

  useEffect(() => {
    if (token?.length || tokenSearchParams?.length) {
      token = tokenSearchParams?.length ? tokenSearchParams : token;
      verifyToken(token);
    } else {
      navigate('/login');
    }

    if (is_paidSearchParams?.length && tokenSearchParams?.length && assigned_idSearchParams?.length) {
      dispatch(setIsModalFinished(true));
      setIsPaid(true);
    }

    if (is_passwordSearchParams?.length && tokenSearchParams?.length) {
      dispatch(setIsModalFinished(true));
      setIsPassword(true);
      // handleUpdatePassword(tokenSearchParams, dispatch, language);
    }

    if (is_emailSearchParams?.length && tokenSearchParams?.length) {
      dispatch(setIsModalFinished(true));
      setIsEmail(true);
      handleUpdateEmail(tokenSearchParams, dispatch, translations, language);
    }

    localStorage.getItem('localLanguage')?.length ? dispatch(setLanguage(localStorage.getItem('localLanguage'))) : dispatch(setLanguage('en'));

    let newUserEmailTime = localStorage.getItem('newUserEmailTime');
    if (newUserEmailTime?.length && handleCheckNewEmailTime(JSON.parse(newUserEmailTime))) {
      localStorage.removeItem('newUserEmail');
      localStorage.removeItem('newUserEmailTime');
    }

    handleCheckNewMessages(token, (res) => dispatch(setIsUnreadMessagesUser(res)));
    const interval = setInterval(() => {
      handleCheckNewMessages(token, (res) => dispatch(setIsUnreadMessagesUser(res)));
    }, 240000);
  }, []);

  useEffect(() => {
    const container = document.getElementById('layoutOutletId');
    if (container) {
      container.scrollTo(0, 0);
    }
  }, [location]);

  useEffect(() => {
    if (userTrainings?.length) {
      handleFilterLocalExercises();
    }
  }, [userTrainings]);

  const handleFilterLocalExercises = () => {
    let data = localStorage.getItem('localExercisesLocation');
    let ids = userTrainings.map((item) => item._id);
    let arrLocal = JSON.parse(data);
    if (arrLocal?.length) {
      let arr = [];
      arr = arrLocal.filter((item) => {
        const parts = item.pathname.split('/');
        const id = parts[3];
        return ids.includes(id);
      });
      localStorage.setItem('localExercisesLocation', JSON.stringify([...arr]));
    }

    let dataRest = localStorage.getItem('localRestTime');
    let restIds = userTrainings.map((item) => item._id);
    let arrRestLocal = JSON.parse(dataRest);
    if (arrRestLocal?.length) {
      let arr = [];
      arr = arrRestLocal.filter((item) => {
        const parts = item.pathname.split('/');
        const id = parts[3];
        return restIds.includes(id);
      });
      localStorage.setItem('localRestTime', JSON.stringify([...arr]));
    }
  };

  const verifyToken = async (token = null) => {
    if (token?.length) {
      fetchGetData(`/auth/verify?token=${token}`).then((res) => {
        if (res?.success && res.data?._id?.length && !res.data?.is_coach) {
          dispatch(setUser(res.data));
          dispatch(setIs_app(res.is_app));
          localStorage.setItem('token-user', token);
        } else {
          handleExit(res?.is_app, false);
        }
      });
    } else {
      handleExit(false, false);
    }
  };

  const handleIsModalFinished = (boolean) => {
    dispatch(setIsModalFinished(false));
    navigate(`/user/${user._id}`);
    setIsPassword(false);
    setIsEmail(false);
    setIsPaid(false);
    const newSearchParams = new URLSearchParams();
    setSearchParams(newSearchParams);
  };

  return (
    <div className="layout">
      {isModalFinished && (
        <ModalWindow
          title={''}
          handleClick={handleIsModalFinished}
          notBtn={true}
        >
          <img
            className="time-progress__modal-img"
            src={isPassword ? dotImg : isEmail ? envelopeImage : finish}
            alt="img"
          />
          <h3 className="time-progress__modal-title">{isPassword ? translations['passwordUpdated'][language] : isEmail ? translations['emailUpdated'][language] : translations['congratulation'][language]}</h3>
          <div className="time-progress__modal-text">
            {isPaid && <span>{translations['paymentSuccessful'][language]}</span>}
            {!!finished_daySearchParams?.length && (
              <div className="time-progress__modal-text layout__modal-text">
                <span>{translations['finishedDay'][language]}</span>
                <span><b>{finished_daySearchParams}</b></span>
              </div>
            )}
            {!!finished_trainingSearchParams?.length && (
              <div className="time-progress__modal-text layout__modal-text">
                <span>{translations['finishedTraining'][language]}</span>
                <span><b>{finished_trainingSearchParams}</b></span>
              </div>
            )}
          </div>
          <div className="time-progress__modal-btn-wrap">
            <button
              className="mainBtnDark mainBtn--whidth"
              onClick={handleIsModalFinished}
            >
              {translations['close'][language]}
            </button>
          </div>
          <ConfettiFinish />
        </ModalWindow>
      )}
      <Header />
      <div
        id="layoutOutletId"
        className="layout__outlet-wrap"
      >
        <Outlet />
      </div>
      <Footer />
      {/* <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar
                    open={isOrderPlaced}
                    autoHideDuration={6000}
                    onClose={() => dispatch(setIsOrderPlaced(false))}
                    anchorOrigin={{ vertical: 'top', horizontal:'right' }}
                >
                    <Alert className='show-message__success' onClose={() => dispatch(setIsOrderPlaced(false))} severity="success" sx={{ width: '100%' }}>
                        Замовлення відправлено на опрацювання
                    </Alert>
                </Snackbar>
            </Stack> */}
    </div>
  );
}

export default Layout;
