import './AdminHeader.css';
import { useState, memo, useRef, useEffect } from 'react';
import notifications from '../../assets/notifications.svg';
import search from '../../assets/search.svg';
import arrowLeft from '../../assets/arrowLeft.svg';
import { NavLink, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Popover } from '@mui/material';
import ModalNotifications from '../ModalNotifications/ModalNotifications';
import { useSelector } from 'react-redux';
import search3 from '../../assets/search3.svg';
import closeImgViolet from '../../assets/closeImgViolet.svg';
import { translations } from '../../localization';
import { useDispatch } from 'react-redux';
import { setIsUnreadMessages, setModalNewNotifications, setModalSeenNotifications } from '../../store/userSlice';
import { handleCheckNewMessages, handleExit, handleUpdatedNotifications } from '../../helpers/Utils';
import defaultAvatar from '../../assets/defaultAvatar.png';
import SelectLanguage from '../SelectLanguage/SelectLanguage';
import logoutImg from '../../assets/logoutImg.svg';

function AdminHeader() {
  const [searchParams, setSearchParams] = useSearchParams();
  const newSearchParams = new URLSearchParams(searchParams);
  const searchValueSearchParams = JSON.parse(searchParams.get('searchValue'));
  const windowInnerWidth = useSelector((state) => state.userSlice.windowInnerWidth);
  const isUnreadMessages = useSelector((state) => state.userSlice.isUnreadMessages);
  const language = useSelector((state) => state.userSlice.language);
  const coach = useSelector((state) => state.userSlice.coach);
  const trainingObj = useSelector((state) => state.userSlice.trainingObj);
  const modalNewNotifications = useSelector((state) => state.userSlice.modalNewNotifications);
  const exerciseObj = useSelector((state) => state.userSlice.exerciseObj);
  const is_app = useSelector((state) => state.homeSlice.is_app);
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchAnchorEl, setSearchAnchorEl] = useState(null);
  const [isNotFirstRender, setIsNotFirstRender] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { coachId } = useParams();
  const { trainingId } = useParams();
  const { dayId } = useParams();
  const { planId } = useParams();
  const inputSearchRef = useRef(null);
  const token = localStorage.getItem('token-admin');

  useEffect(() => {
    setIsNotFirstRender(true);
  }, []);

  const handleSeeAllNotifications = () => {
    setAnchorEl(null);
    dispatch(setModalSeenNotifications([]));
    dispatch(setModalNewNotifications([]));
  };

  const handleCloseModalNotifications = () => {
    setAnchorEl(null);
    if (modalNewNotifications?.length) {
      let data = {
        notifications_id: modalNewNotifications.map((el) => el._id),
      };
      handleUpdatedNotifications(token, data, handleClearNewNotifications);
      setTimeout(() => {
        handleCheckNewMessages(token, (res) => dispatch(setIsUnreadMessages(res)));
      }, 500);
    }
  };

  const handleClearNewNotifications = () => {
    dispatch(setModalNewNotifications([]));
    dispatch(setModalNewNotifications([]));
  };

  const handleClearSearch = () => {
    newSearchParams.set('searchValue', JSON.stringify(''));
    setSearchParams(newSearchParams);
  };

  const handleBlockSearch = () => {
    let test = true;
    if (location.pathname.startsWith(`/auth/${coachId}/profile`) || location.pathname.startsWith(`/auth/${coachId}/trainings/`) || location.pathname.startsWith(`/auth/${coachId}/not-found`) || location.pathname.startsWith(`/auth/${coachId}/billing`)) {
      test = false;
    }
    return test;
  };

  const handleOpenSearch = (e) => {
    setSearchAnchorEl(e.currentTarget);
    setTimeout(() => {
      if (inputSearchRef) {
        inputSearchRef.current.focus();
      }
    }, 100);
  };

  const handleCloseSearch = () => {
    setSearchAnchorEl(null);
  };

  const handleSearch = (str) => {
    newSearchParams.set('searchValue', JSON.stringify(str));
    setSearchParams(newSearchParams);
  };

  const handleReturnTitle = () => {
    let testCreate = location.pathname.split('/').includes('create');
    if (location.pathname.split('/').includes('notifications')) return translations['notifications'][language];
    if (location.pathname.split('/').includes('profile')) return translations['profile'][language];
    if (location.pathname.split('/').includes('users')) return translations['users'][language];
    if (location.pathname === `/auth/${coachId}/plans/create`) return translations['addExercise'][language];
    if (location.pathname === `/auth/${coachId}/trainings/create`) return translations['addTraining'][language];
    if (location.pathname === `/auth/${coachId}/trainings`) return translations['trainings'][language];
    if (location.pathname === `/auth/${coachId}/plans`) return translations['exercises'][language];
    if (planId?.length) return exerciseObj.is_exercise ? translations['editExercise'][language] : translations['editRestTime'][language];
    if (testCreate && dayId?.length) return translations['addDay'][language];
    if (dayId?.length) return translations['editDay'][language];
    if (trainingId?.length && trainingObj._id?.length) return translations['editTraining'][language];
    if (coach?.first_name?.length) return `Hello, ${coach?.first_name}`;
  };

  return (
    <div className="admin-header">
      {windowInnerWidth < 640 ? (
        <div className="admin-header__btn-back-wrap">
          {
            (location.pathname.includes('/trainings/create') || location.pathname.includes('/plans/create')) && (
              <button
                className="admin-header__btn-back"
                onClick={() => navigate(-1)}
              >
                <img
                  className="admin-header__btn-back-img"
                  src={arrowLeft}
                  alt="img"
                />
              </button>
            )
          }
          <p className="admin-header__btn-back-text">{handleReturnTitle()}</p>
        </div>
      ) : (
        <>
          {location.pathname.startsWith(`/auth/${coachId}/trainings/`) || location.pathname.startsWith(`/auth/${coachId}/users/`) || location.pathname.startsWith(`/auth/${coachId}/plans/`) || location.pathname.startsWith(`/auth/${coachId}/profile`) ? (
            <div className="admin-header__btn-back-wrap">
              <button
                className="admin-header__btn-back"
                onClick={() => navigate(-1)}
              >
                <img
                  className="admin-header__btn-back-img"
                  src={arrowLeft}
                  alt="img"
                />
              </button>
              <p className="admin-header__btn-back-text">{handleReturnTitle()}</p>
            </div>
          ) : (
            <div className={`admin-header__search-wrap ${(location.pathname.startsWith(`/auth/${coachId}/not-found`) || location.pathname.startsWith(`/auth/${coachId}/billing`)) && 'admin-header__search-wrap--disabled'}`}>
              <input
                className={`admin-header__search ${searchValueSearchParams?.length && 'admin-header__search--active'}`}
                onChange={(e) => handleSearch(e.target.value)}
                onKeyDown={(e) => e.key === 'Enter' && handleSearch(searchValueSearchParams)}
                value={searchValueSearchParams || ''}
                type="text"
                placeholder={translations['search'][language]}
              />
              {!!searchValueSearchParams?.length ? (
                <img
                  className="admin-header__search-modal-btn-img admin-header__search-modal-btn-delete-img"
                  src={closeImgViolet}
                  onClick={handleClearSearch}
                  alt="img"
                />
              ) : (
                <img
                  className="admin-header__search-img"
                  onClick={handleSearch}
                  src={search}
                  alt="img"
                />
              )}
            </div>
          )}
        </>
      )}
      <div className="admin-header__btn-wrap">
        {handleBlockSearch() && (
          <>
            <button
              aria-describedby={'simple-popover-search'}
              className="admin-header__btn-search"
              onClick={(e) => handleOpenSearch(e)}
            >
              <img
                src={search3}
                alt="img"
              />
              {!!searchValueSearchParams?.length && <div className="admin-header__btn-new-notifications"></div>}
            </button>
            <Popover
              className="admin-header__search-modal--wrap"
              id={'simple-popover-search'}
              open={Boolean(searchAnchorEl)}
              anchorEl={searchAnchorEl}
              onClose={handleCloseSearch}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'center',
                horizontal: 'center',
              }}
            >
              <div className="admin-header__search-modal-wrap">
                <input
                  ref={inputSearchRef}
                  className="admin-header__search-modal-input"
                  onChange={(e) => handleSearch(e.target.value)}
                  onKeyDown={(e) => e.key === 'Enter' && handleSearch(searchValueSearchParams)}
                  value={searchValueSearchParams || ''}
                  type="text"
                  placeholder={`${translations['search'][language]}`}
                />
                {!!searchValueSearchParams?.length ? (
                  <img
                    className="admin-header__search-modal-btn-img admin-header__search-modal-btn-delete-img"
                    src={closeImgViolet}
                    onClick={handleClearSearch}
                    alt="img"
                  />
                ) : (
                  <img
                    className="admin-header__search-modal-btn-img"
                    src={search}
                    alt="img"
                  />
                )}
              </div>
            </Popover>
          </>
        )}
        <SelectLanguage />
        <button
          aria-describedby={'simple-popover-notifications'}
          className="admin-header__btn"
          onClick={(e) => setAnchorEl(e.currentTarget)}
        >
          <img
            src={notifications}
            alt="img"
          />
          {isUnreadMessages && <div className="admin-header__btn-new-notifications"></div>}
        </button>
        <Popover
          id={'simple-popover-notifications'}
          className="admin-header__notifications-modal--wrap"
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleCloseModalNotifications}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: -5,
            horizontal: 'center',
          }}
        >
          <ModalNotifications
            handleClose={handleSeeAllNotifications}
            is_coach={true}
          />
        </Popover>
        {((location.pathname.split('/').includes('profile') && windowInnerWidth < 640) || (location.pathname.split('/').includes('billing') && windowInnerWidth < 640)) && (
          <button
            className="admin-header__btn"
            onClick={() => handleExit(is_app, true)}
          >
            <img
              src={logoutImg}
              alt="img"
            />
          </button>
        )}
        <NavLink
          className="admin-header__user-img-wrap"
          to={`/auth/${coachId}/profile`}
        >
          <img
            className={`admin-header__user-img ${location.pathname.split('/').includes('profile') ? 'admin-header__user-img--active' : ''}`}
            src={coach?.image?.length ? coach.image : defaultAvatar}
            alt="img"
            referrerPolicy="no-referrer"
          />
          {!coach.image?.length && (
            <div className="admin-header__user-img-name">
              {coach.first_name.slice(0, 1)}
              {coach.last_name.slice(0, 1)}
            </div>
          )}
        </NavLink>
      </div>
    </div>
  );
}

export default memo(AdminHeader);
