import './AdminCreatePlanView.css';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import CreateExercise from '../../components/CreateExercise/CreateExercise';
import { useDispatch } from 'react-redux';
import { setExercises, setBackToCreateTrening, setErrorExerciseObj } from '../../store/userSlice';
import { useSelector } from 'react-redux';
import DoubleButtons from '../../components/DoubleButtons/DoubleButtons';
import { pageMeta } from '../../pageMeta';
import PageMeta from '../../components/PageMeta/PageMeta';
import { translations } from '../../localization';
import PreloaderCustom from '../../components/PreloaderCustom/PreloaderCustom';
import { setShowMessageObj } from '../../store/homeSlice';
import { fetchRequest, logIssue } from '../../helpers/Utils';
import Uploader from '../../helpers/Uploader';
import { WARNING } from '../../helpers/Config';

function AdminCreatePlanView() {
  const [searchParams, setSearchParams] = useSearchParams();
  const newSearchParams = new URLSearchParams(searchParams);
  const createTabSearchParams = JSON.parse(searchParams.get('createTab'));
  const backToCreateTrening = useSelector((state) => state.userSlice.backToCreateTrening);
  const language = useSelector((state) => state.userSlice.language);
  const exerciseObj = useSelector((state) => state.userSlice.exerciseObj);
  const exercises = useSelector((state) => state.userSlice.exercises);
  const coach = useSelector((state) => state.userSlice.coach);
  const [isExercise, setIsExercise] = useState(true);
  const [isPreloader, setIsPreloader] = useState(false);
  const [isNotFirstRender, setIsNotFirstRender] = useState(false);
  const [createTab, setCreateTab] = useState(createTabSearchParams ? createTabSearchParams : 1);
  const [progressVideo, setProgressVideo] = useState(0);
  const { coachId } = useParams();
  const { planId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let token = localStorage.getItem('token-admin');

  useEffect(() => {
    setIsNotFirstRender(true);

    return () => {
      if (backToCreateTrening) {
        dispatch(setBackToCreateTrening(''));
      }
      dispatch(setErrorExerciseObj({}));
    };
  }, []);

  useEffect(() => {
    if (isNotFirstRender && createTab !== createTabSearchParams) {
      setCreateTab(createTabSearchParams ? createTabSearchParams : 1);
    }
  }, [createTabSearchParams]);

  const handleSelectExercise = (boolean) => {
    setIsExercise(boolean);
    if (createTab === 2) {
      setCreateTab(1);
      newSearchParams.set('createTab', JSON.stringify(1));
      setSearchParams(newSearchParams);
    }
  };
  const handleNextTab = () => {
    let test1 = exerciseObj?.newVideo?.name?.length;
    let test2 = exerciseObj?.video?.length;
    if ((test1 || test2) && exerciseObj.name?.length && exerciseObj.number_sets && exerciseObj.time && exerciseObj.number_repetitions) {
      setCreateTab(2);
      newSearchParams.set('createTab', JSON.stringify(2));
      setSearchParams(newSearchParams);
      dispatch(setErrorExerciseObj({}));
    } else {
      dispatch(
        setErrorExerciseObj({
          isErrorName: !exerciseObj?.name?.length ? true : false,
          isErrorNumber_sets: !exerciseObj?.number_sets ? true : false,
          isErrorNumber_repetitions: !exerciseObj?.number_repetitions ? true : false,
          isErrorTime: !exerciseObj?.time ? true : false,
          isErrorVideo: !exerciseObj?.video?.length ? true : false,
        }),
      );
      dispatch(setShowMessageObj({ open: true,
        status: 'error', 
        message: `${translations['errorExercise'][language]} 
          ${!exerciseObj?.video?.length ? translations['video'][language] + ',' : ''} 
          ${!exerciseObj?.name?.length ? translations['exerciseName'][language] + ',' : ''} 
          ${!exerciseObj?.number_sets ? translations['numberOfSets'][language] + ',' : ''} 
          ${!exerciseObj?.number_repetitions ? translations['numberOfRepetitions'][language] + ',' : ''} 
          ${!exerciseObj?.time ? translations['numberMinutes'][language] : ''}
        `}));
    }
  };

  const handleUpload = async (file) => {
    if (file) {
      const chunkSize = 1024 * 1024 * 5;
      const uploader = new Uploader({
        chunkSize: file.size <= chunkSize ? file.size : chunkSize,
        threadsQuantity: 5,
        file: file,
        fileName: file.name,
      });

      return new Promise((resolve, reject) => {
        uploader.onProgress((progress) => {
          setProgressVideo(progress.percentage);
        });

        uploader.onError((error) => {
          console.error('Error:', error);
          logIssue(WARNING, "handleUpload", error);
          reject(error);
        });

        uploader.onComplete((result) => {
          if (result?.success && result?.data) {
            resolve(result.data);
          } else {
            resolve('');
          }
        });

        uploader.start();
      });
    } else {
      console.error('No file selected');
      logIssue(WARNING, "handleUpload No file selected", file);
      return Promise.reject('No file selected');
    }
  };

  const handleUpdateExercise = (data) => {
    setIsPreloader(true);
    fetchRequest('PUT', `/exercises/${planId}?token=${token}`, data).then((res) => {
      if (res?.success && res.data) {
        dispatch(setExercises([...exercises.map((exercise) => (exercise._id === planId ? res.data : exercise))]));
        if (backToCreateTrening?.length) {
          navigate(backToCreateTrening);
        } else {
          navigate(`/auth/${coachId}/plans`);
        }
        if (res.data.is_exercise) {
          dispatch(setShowMessageObj({ open: true, status: 'success', message: translations['exerciseUpdated'][language] }));
        } else {
          dispatch(setShowMessageObj({ open: true, status: 'success', message: translations['restTimeUpdated'][language] }));
        }
      } else {
        dispatch(setShowMessageObj({ open: true, status: 'error', message: res?.statusCode === 401 ? translations['errorName'][language] : translations['anErrorOccurred'][language] }));
        logIssue(WARNING, "handleUpdateExercise AdminCreatePlanView", res);
      }
      setIsPreloader(false);
    });
  };

  const handleCreateExercise = (data) => {
    setIsPreloader(true);
    fetchRequest('POST', `/exercises?token=${token}`, data).then((res) => {
      if (res?.success && res?.data) {
        dispatch(setExercises([res.data, ...exercises]));
        if (backToCreateTrening?.length) {
          navigate(backToCreateTrening);
        } else {
          navigate(`/auth/${coachId}/plans`);
        }
        dispatch(setShowMessageObj({ open: true, status: 'success', message: translations['exerciseCreated'][language] }));
      } else {
        dispatch(setShowMessageObj({ open: true, status: 'error', message: res?.statusCode === 401 ? translations['errorName'][language] : translations['anErrorOccurred'][language] }));
        logIssue(WARNING, "handleCreateExercise AdminCreatePlanView", res);
      }
      setIsPreloader(false);
    });
  };

  const handleCreateRestTime = (data) => {
    setIsPreloader(true);
    fetchRequest('POST', `/exercises/rest?token=${token}`, data).then((res) => {
      if (res?.success && res?.data) {
        dispatch(setExercises([res.data, ...exercises]));
        if (backToCreateTrening?.length) {
          navigate(backToCreateTrening);
        } else {
          navigate(`/auth/${coachId}/plans`);
        }
        dispatch(setShowMessageObj({ open: true, status: 'success', message: translations['restTimeCreated'][language] }));
      } else {
        dispatch(setShowMessageObj({ open: true, status: 'error', message: translations['anErrorOccurred'][language] }));
        logIssue(WARNING, "handleCreateRestTime AdminCreatePlanView", res);
      }
      setIsPreloader(false);
    });
  };

  const handleSaveExercise = async () => {
    try {
      if (planId?.length) {
        if (isExercise) {
          let test1 = exerciseObj?.newVideo?.name?.length;
          let test2 = exerciseObj?.newImage?.length;
          let test3 = exerciseObj?.newPreviewImage?.length;
          if ((test1 || test2 || test3 || exerciseObj?.video?.length) && exerciseObj?.name?.length && exerciseObj?.number_sets && exerciseObj?.time && exerciseObj?.number_repetitions) {
            setIsPreloader(true);
            let data = {};
            const excludedFields = ['newVideo', 'newImage', 'newPreviewImage'];
            for (let key in exerciseObj) {
              if (!excludedFields.includes(key)) {
                data[key] = exerciseObj[key];
              }
            }
            if (test1) {
              data.video = await handleUpload(exerciseObj.newVideo);
            }
            if (exerciseObj?.newImage?.length) {
              data.image = exerciseObj?.newImage;
            }
            if (exerciseObj?.newPreviewImage?.length) {
              data.preview = exerciseObj?.newPreviewImage;
            }
            data.newImage = '';
            data.newVideo = {};
            data.newPreviewImage = '';
            handleUpdateExercise(data);
          } else {
            dispatch(
              setErrorExerciseObj({
                isErrorName: !exerciseObj?.name?.length ? true : false,
                isErrorNumber_sets: !exerciseObj?.number_sets ? true : false,
                isErrorNumber_repetitions: !exerciseObj?.number_repetitions ? true : false,
                isErrorTime: !exerciseObj?.time ? true : false,
                isErrorVideo: !test1 && !test2 && !test3 && !exerciseObj?.video?.length ? true : false,
              }),
            );
            dispatch(setShowMessageObj({ open: true,
              status: 'error', 
              message: `${translations['errorExercise'][language]} 
                ${!exerciseObj?.video?.length ? translations['video'][language] + ',' : ''} 
                ${!exerciseObj?.name?.length ? translations['exerciseName'][language] + ',' : ''} 
                ${!exerciseObj?.number_sets ? translations['numberOfSets'][language] + ',' : ''} 
                ${!exerciseObj?.number_repetitions ? translations['numberOfRepetitions'][language] + ',' : ''} 
                ${!exerciseObj?.time ? translations['numberMinutes'][language] : ''}
              `}));
          }
        } else if (!isExercise) {
          if (exerciseObj?.time) {
            let data = {
              time: exerciseObj.time,
              coach_id: coach._id,
              is_exercise: false,
            };
            handleUpdateExercise(data);
          } else {
            dispatch(
              setErrorExerciseObj({
                isErrorTime: !exerciseObj?.time ? true : false,
              }),
            );
            dispatch(setShowMessageObj({ open: true, status: 'error', message: translations['enterRestTime'][language] }));
          }
        }
      } else {
        if (isExercise) {
          // let test1 = exerciseObj?.newVideo?.name?.length;
          // let test2 = exerciseObj?.newImage?.length;
          // let test3 = exerciseObj?.newPreviewImage?.length;
          let test1 = !!exerciseObj?.newVideo?.name;
          let test2 = !!exerciseObj?.newImage;
          let test3 = !!exerciseObj?.newPreviewImage;
          if ((test1 || test2 || test3 || exerciseObj?.video?.length) && exerciseObj?.name?.length && exerciseObj?.number_sets && exerciseObj?.time && exerciseObj?.number_repetitions) {
            setIsPreloader(true);
            let data = {...exerciseObj};
            if (test1) {
              data.video = await handleUpload(exerciseObj.newVideo);
            }
            if (exerciseObj?.newImage?.length) {
              data.image = exerciseObj?.newImage;
            }
            if (exerciseObj?.newPreviewImage?.length) {
              data.preview = exerciseObj?.newPreviewImage;
            }
            data.newImage = '';
            data.newVideo = {};
            data.newPreviewImage = '';
            handleCreateExercise(data);
          } else {
            dispatch(
              setErrorExerciseObj({
                isErrorName: !exerciseObj?.name?.length ? true : false,
                isErrorNumber_sets: !exerciseObj?.number_sets ? true : false,
                isErrorNumber_repetitions: !exerciseObj?.number_repetitions ? true : false,
                isErrorTime: !exerciseObj?.time ? true : false,
                isErrorVideo: !test1 && !test2 && !test3 && !exerciseObj?.video?.length ? true : false,
              }),
            );
            dispatch(setShowMessageObj({ open: true,
              status: 'error', 
              message: `${translations['errorExercise'][language]} 
                ${!exerciseObj?.video?.length ? translations['video'][language] + ',' : ''} 
                ${!exerciseObj?.name?.length ? translations['exerciseName'][language] + ',' : ''} 
                ${!exerciseObj?.number_sets ? translations['numberOfSets'][language] + ',' : ''} 
                ${!exerciseObj?.number_repetitions ? translations['numberOfRepetitions'][language] + ',' : ''} 
                ${!exerciseObj?.time ? translations['numberMinutes'][language] : ''}
              `}));
          }
        } else if (!isExercise) {
          if (exerciseObj?.time) {
            let data = {
              time: exerciseObj.time,
              coach_id: coach._id,
              is_exercise: false,
            };
            handleCreateRestTime(data);
          } else {
            dispatch(
              setErrorExerciseObj({
                isErrorTime: !exerciseObj?.time ? true : false,
              }),
            );
            dispatch(setShowMessageObj({ open: true, status: 'error', message: translations['enterRestTime'][language] }));
          }
        }
      }
    } catch (error) {
      setIsPreloader(false);
      dispatch(setShowMessageObj({ open: true, status: 'error', message: translations['errorCreate'][language] }));
      logIssue(WARNING, "An error occurred in handleSaveExercise", error);
    }
  };

  return (
    <div className="admin-create-plan-wrap">
      <PageMeta {...pageMeta['AdminCreatePlanView']} />
      {isPreloader && <PreloaderCustom progressVideo={progressVideo} />}
      <div className="admin-create-plan">
        <div className="admin-create-plan__input-wrap">
          <div className="admin-create-plan__input--wrap">
            {!planId?.length && (
              <div className="admin-create-plan__btn-wrap">
                <DoubleButtons
                  isActive={isExercise}
                  leftBtnText={translations['exercise'][language]}
                  rightBtnText={translations['restTime'][language]}
                  onClickLeftBtn={() => handleSelectExercise(true)}
                  onClickRightBtn={() => handleSelectExercise(false)}
                />
              </div>
            )}
            <div className={`admin-create-plan__create-wrap`}>
              <CreateExercise
                createTab={createTab}
                isExercise={isExercise}
                setIsExercise={setIsExercise}
                setIsPreloader={setIsPreloader}
                isPreloader={isPreloader}
              />
            </div>
          </div>
        </div>
        <div className="admin-create-plan__add-btn-wrap">
          {createTab === 1 ? (
            <>
              {isExercise ? (
                <button
                  className="mainBtnDark"
                  onClick={handleNextTab}
                >
                  {translations['next'][language]}
                </button>
              ) : (
                <button
                  className="mainBtnDark"
                  onClick={handleSaveExercise}
                >
                  {translations['save'][language]}
                </button>
              )}
            </>
          ) : (
            <>
              <button
                className="mainBtnWhite"
                onClick={() => navigate(-1)}
              >
                {translations['back'][language]}
              </button>
              <button
                className="mainBtnDark"
                onClick={handleSaveExercise}
              >
                {translations['save'][language]}
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default AdminCreatePlanView;
