import './AdminProfileView.css';
import { NavLink, useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import TextInput from '../../components/TextInput/TextInput';
import editBgWhite from '../../assets/editBgWhite.svg';
import PhoneInput from 'react-phone-input-2';
import PasswordInput from '../../components/PasswordInput/PasswordInput';
import { useSelector } from 'react-redux';
import { pageMeta } from '../../pageMeta';
import PageMeta from '../../components/PageMeta/PageMeta';
import { translations } from '../../localization';
import { setShowMessageObj } from '../../store/homeSlice';
import { setCoach, setIsInputFocus } from '../../store/userSlice';
import PreloaderCustom from '../../components/PreloaderCustom/PreloaderCustom';
import { useDispatch } from 'react-redux';
import { fetchGetData, fetchRequest, handleDeleteAccount, handleFocusAppInput, handleSaveMedia, handleSendUpdateEmail, handleUploadImgCompressor, logIssue, validEmail, validatePassword, validatePhone } from '../../helpers/Utils';
import editViolet from '../../assets/editViolet.svg';
import ModalWindow from '../../components/ModalWindow/ModalWindow';
import defaultAvatar from '../../assets/defaultAvatar.png';
import CountdownTimer from '../../components/CountdownTimer/CountdownTimer';
import { Popover, Slider, Typography } from '@mui/material';
import Cropper from 'react-easy-crop';
import getCroppedImg from '../../helpers/cropImage';
import { COMPANY, INDIVIDUAL, WARNING } from '../../helpers/Config';
import helpIcon from '../../assets/helpIcon.svg';

function AdminProfileView() {
  const language = useSelector((state) => state.userSlice.language);
  const windowInnerWidth = useSelector((state) => state.userSlice.windowInnerWidth);
  const coach = useSelector((state) => state.userSlice.coach);
  const is_app = useSelector((state) => state.homeSlice.is_app);
  const [firstName, setFirstName] = useState(coach?.first_name?.length ? coach?.first_name : '');
  const [lastName, setLastName] = useState(coach.last_name?.length ? coach.last_name : '');
  const [phone, setPhone] = useState(coach.phone?.length ? coach.phone : '');
  const [email, setEmail] = useState(coach.email?.length ? coach.email : '');
  const [image, setImage] = useState(coach.image?.length ? coach.image : '');
  const [userLocation, setUserLocation] = useState(coach.location?.length ? coach.location : '');
  const [country, setCountry] = useState('');
  const [password, setPassword] = useState('12345678');
  const [existingPassword, setExistingPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newImage, setNewImage] = useState({});
  const [isPreloader, setIsPreloader] = useState(true);
  const [isModalNewPassword, setIsModalNewPassword] = useState(false);
  const [isModalCreatePassword, setIsModalCreatePassword] = useState(false);
  const [isErrorPassword, setIsErrorPassword] = useState(false);
  const [isErrorFirstName, setIsErrorFirstName] = useState(false);
  const [isErrorConfirmPassword, setIsErrorConfirmPassword] = useState(false);
  const [isErrorLastName, setIsErrorLastName] = useState(false);
  const [isErrorPhone, setIsErrorPhone] = useState(false);
  const [isModalNewEmail, setIsModalNewEmail] = useState(false);
  const [isErrorNewEmail, setIsErrorNewEmail] = useState(false);
  const [isNewEmail, setIsNewEmail] = useState(false);
  const [isStartTimer, setIsStartTimer] = useState(false);
  const [newEmail, setNewEmail] = useState('');
  const [anchorImageEl, setAnchorImageEl] = useState(null);
  const [isEditeImage, setIsEditeImage] = useState(false);
  const [isUploadImg, setIsUploadImg] = useState(false);
  const [isErrorBusiness_name, setIsErrorBusiness_name] = useState(false);
  const [business_name, setBusiness_name] = useState('');
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [isModalDeleteAccount, setIsModalDeleteAccount] = useState(false);
  const [isBtnLoader, setIsBtnLoader] = useState(false);
  const dispatch = useDispatch();
  const timeoutRef = useRef(null);
  const { coachId } = useParams();
  let token = localStorage.getItem('token-admin');

  useEffect(() => {
    handleCheckLocalNewEmail();

    return () => {
      if (timeoutRef.current) {
        clearInterval(timeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (coach._id?.length) {
      setEmail(coach.email);
      setPhone(coach.phone);
      setLastName(coach.last_name);
      setFirstName(coach.first_name);
      setUserLocation(coach.location);
      setImage(coach.image);
      setIsPreloader(false);
      setBusiness_name(coach.business_name);
    }
  }, [coach]);

  useEffect(() => {
    setIsErrorPassword(false);
    setIsErrorConfirmPassword(false);
  }, [existingPassword, newPassword, confirmPassword]);

  useEffect(() => {
    setIsErrorFirstName(false);
  }, [firstName]);

  useEffect(() => {
    setIsErrorLastName(false);
  }, [lastName]);

  useEffect(() => {
    setIsErrorPhone(false);
  }, [phone]);

  useEffect(() => {
    setIsErrorNewEmail(false);
  }, [newEmail]);

  const handleSave = async () => {
    let testPhone = phone?.length && validatePhone(phone, country) ? false : true;
    let testFirstName = firstName?.length ? false : true;
    let testLastName = lastName?.length ? false : true;
    let testBusiness_name = (coach?.business_type === COMPANY && business_name?.length) || !coach?.business_type || coach?.business_type === INDIVIDUAL ? true : false;
    if (firstName?.length && lastName?.length && password?.length && (!testPhone || !phone?.length) && testBusiness_name) {
      if (newImage?.name?.length) {
        let str = await handleSaveMedia(token, newImage);
        if (str?.length) {
          handleUpdateUser(str);
        }
        return;
      }
      handleUpdateUser();
    } else {
      setIsErrorPhone(testPhone);
      setIsErrorFirstName(testFirstName);
      setIsErrorLastName(testLastName);
      setIsErrorBusiness_name(!testBusiness_name);
      dispatch(
        setShowMessageObj({
          open: true,
          status: 'error',
          message: `${translations['userUpdate'][language]} ${testFirstName ? translations['firstName'][language] : ''} ${testLastName ? translations['lastName'][language] : ''} ${testPhone ? translations['phone'][language] : ''} ${!testBusiness_name ? translations['businessName'][language] : ''}`,
        }),
      );
    }
  };

  const handleUpdateUser = (str = '') => {
    setIsPreloader(true);
    let data = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone: phone,
      location: userLocation,
      business_name: business_name,
      image: str?.length ? str : image?.length ? image : '',
    };
    fetchRequest('PUT', `/users/${coach?._id}?token=${token}`, data).then((res) => {
      if (res?.success && res.data) {
        dispatch(setCoach(res.data));
        dispatch(setShowMessageObj({ open: true, status: 'success', message: translations['dataUpdated'][language] }));
      } else {
        dispatch(setShowMessageObj({ open: true, status: 'error', message: translations['anErrorOccurred'][language] }));
        logIssue(WARNING, "handleUpdateUser AdminProfileView", res);
      }
      setIsPreloader(false);
      setIsModalCreatePassword(false);
    });
  };

  const handleUpdateUserPassword = () => {
    let data = {
      _id: coach?._id,
      new_password: newPassword,
    };
    fetchRequest('POST', `/auth/update-password?token=${token}`, data).then((res) => {
      if (res?.success && res.data) {
        dispatch(setShowMessageObj({ open: true, status: 'success', message: translations['passwordUpdated'][language] }));
      } else {
        dispatch(setShowMessageObj({ open: true, status: 'error', message: translations['anErrorOccurred'][language] }));
        logIssue(WARNING, "handleUpdateUserPassword AdminProfileView", res);
      }
      setIsModalCreatePassword(false);
      setExistingPassword('');
      setConfirmPassword('');
      setNewPassword('');
    });
  };

  const handleUploadImg = async (file = null) => {
    if (file && file[0]?.name?.length) {
      setIsUploadImg(true);
      if (window.innerWidth < 640) {
        let obj = await handleUploadImgCompressor(file);
        if (obj !== null) {
          let str = await handleSaveMedia(token, obj);
          if (str?.length) {
            setImage(str);
          }
        }
      } else {
        let str = await handleSaveMedia(token, file[0]);
        if (str?.length) {
          setImage(str);
        }
      }
      setIsUploadImg(false);
    }
  };

  const handleIsModalNewPassword = (boolean) => {
    if (boolean) {
      if (existingPassword?.length) {
        let data = {
          email: coach.email,
          password: existingPassword,
        };

        fetchRequest('POST', `/auth/check?token=${token}`, data).then((res) => {
          if (res?.success && res.data) {
            setIsModalCreatePassword(true);
            setIsModalNewPassword(false);
            setIsErrorPassword(false);
          } else {
            setIsErrorPassword(true);
          }
        });
      }
    } else {
      handleClearInput();
    }
  };

  const handleIsModalCreatePassword = (boolean) => {
    if (boolean) {
      if (newPassword === confirmPassword && newPassword !== existingPassword && validatePassword(newPassword)) {
        handleUpdateUserPassword();
      } else {
        setIsErrorPassword(true);
        setIsErrorConfirmPassword(newPassword !== confirmPassword ? true : false);
      }
    } else {
      handleClearInput();
    }
  };

  const handleClearInput = () => {
    setIsModalCreatePassword(false);
    setIsModalNewPassword(false);
    setIsErrorPassword(false);
    setIsErrorConfirmPassword(false);
    setNewPassword('');
    setConfirmPassword('');
    setExistingPassword('');
    setIsModalNewEmail(false);
    setIsErrorNewEmail(false);
  };

  const handlePhoneChange = (value, country) => {
    setPhone(value);
    setCountry(country.countryCode);
  };

  const handleIsUpdateEmail = (res) => {
    if (res?.success && res.data) {
      handleClearInput();
      localStorage.setItem('newEmail', JSON.stringify(newEmail));
      localStorage.setItem('newEmailTime', JSON.stringify(new Date().getTime()));
      setIsNewEmail(true);
      dispatch(setShowMessageObj({ open: true, status: 'success', message: translations['messageSent'][language] }));
    } else {
      dispatch(setShowMessageObj({ open: true, status: 'error', message: res?.statusCode === 401 ? translations['errorRegisterEmail'][language] : translations['anErrorOccurred'][language] }));
      setIsErrorNewEmail(true);
      logIssue(WARNING, "handleIsUpdateEmail AdminProfileView", res);
    }
    setIsPreloader(false);
  };

  const handleUpdateEmail = () => {
    setIsPreloader(true);
    let data = {
      email: newEmail,
    };
    handleSendUpdateEmail(token, data, handleIsUpdateEmail);
  };

  const handleResendMail = async () => {
    let newCoach = await handleGetUser();
    if (newCoach?._id?.length && newCoach?.email === newEmail) {
      handleCleanLocal();
    } else {
      handleUpdateEmail();
      setIsStartTimer(true);
    }
  };

  const handleIsModalNewEmail = (boolean) => {
    if (boolean) {
      if (newEmail?.length && validEmail(newEmail)) {
        handleUpdateEmail();
      } else {
        setIsErrorNewEmail(newEmail?.length && validEmail(newEmail) ? false : true);
      }
    } else {
      handleClearInput();
      if (!isNewEmail) {
        setNewEmail('');
      } else {
        let localNewEmail = localStorage.getItem('newEmail');
        if (localNewEmail?.length) {
          setNewEmail(JSON.parse(localNewEmail));
        }
      }
    }
  };

  const handleGetUser = async () => {
    let newCoach = await fetchGetData(`/users/${coachId}?token=${token}`).then((res) => {
      if (res?.success && res?.data) {
        dispatch(setCoach(res.data));
        return res.data;
      } else {
        return null;
      }
    });
    return newCoach;
  };

  const handleCleanLocal = () => {
    setNewEmail('');
    setIsNewEmail(false);
    localStorage.removeItem('newEmail');
    localStorage.removeItem('newEmailTime');
    localStorage.removeItem('startTime');
    localStorage.removeItem('savedTimer');
  };

  const handleCheckLocalNewEmail = async () => {
    let localNewEmail = localStorage.getItem('newEmail');
    if (localNewEmail?.length) {
      let email = JSON.parse(localNewEmail);
      if (email?.length && coach._id?.length && email === coach.email) {
        handleCleanLocal();
        return;
      }
      if (email?.length && coach._id?.length && email !== coach.email) {
        let res = await handleGetUser();
        if (res?._id?.length && res?.email === email) {
          handleCleanLocal();
          if (timeoutRef.current) {
            clearInterval(timeoutRef.current);
            timeoutRef.current = null;
          }
          return;
        }
        setIsNewEmail(true);
        setNewEmail(email);
        const startTime = localStorage.getItem('startTime');
        const savedTimer = localStorage.getItem('savedTimer');
        if (startTime && savedTimer) {
          setIsStartTimer(true);
        }
        timeoutRef.current = setTimeout(() => {
          handleCheckLocalNewEmail();
        }, 3000);
      }
    }
  };

  const handleClosePopover = () => {
    setAnchorImageEl(null);
    setIsEditeImage(true);
  };

  const handleIsEditeImage = (boolean) => {
    if (boolean) {
      showCroppedImage();
    } else {
      setIsEditeImage(false);
    }
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const showCroppedImage = async () => {
    try {
      const croppedImage = await getCroppedImg(image, croppedAreaPixels, rotation);
      setNewImage(croppedImage);
      setIsEditeImage(false);
    } catch (e) {
      console.error(e);
      logIssue(WARNING, "showCroppedImage AdminProfileView", e);
    }
  };

  const handleIsModalDeleteAccount = (boolean) => {
    if (boolean) {
      setIsBtnLoader(true);
      handleDeleteAccount(token, coach._id, is_app, true, dispatch, language, () => setIsBtnLoader(false));
    }
    setIsModalDeleteAccount(false);
  };

  return (
    <div className="admin-profile-wrap">
      <PageMeta {...pageMeta['AdminProfileView']} />
      {isPreloader && <PreloaderCustom />}
      {isModalDeleteAccount && (
        <ModalWindow
          title={''}
          handleClick={handleIsModalDeleteAccount}
        >
          <div className="admin-trainings__dialogform-delete-wrap">
            <img
              className="admin-trainings__dialogform-delete-img"
              src={helpIcon}
              alt="img"
            />
            <div className="admin-trainings__dialogform-delete-text">
              <div>{translations['AreYouSureDeleteAccount'][language]}</div>
            </div>
          </div>
        </ModalWindow>
      )}
      {isEditeImage && (
        <ModalWindow
          title={''}
          handleClick={handleIsEditeImage}
          addStyles={{
            width: '100%',
            maxWidth: windowInnerWidth < 640 ? '100%' : '90%',
            height: '100%',
            maxHeight: windowInnerWidth < 640 ? '100%' : '90%',
            borderRadius: windowInnerWidth < 640 ? '0px' : '27px',
            padding: windowInnerWidth < 640 ? '16px 10px' : '16px 24px',
          }}
        >
          <div className="admin-training__modal-edite-image">
            {!!image?.length && (
              <div className="admin-training__create-img-cropper-wrap">
                <Cropper
                  image={image?.length ? image : ''}
                  crop={crop}
                  zoom={zoom}
                  rotation={rotation}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                  onRotationChange={setRotation}
                  cropSize={{ width: 150, height: 150 }}
                  minZoom={0.1}
                  zoomSpeed={0.05}
                  objectFit={'cover'}
                />
              </div>
            )}
            <div className="admin-training__modal-edite-image-slider">
              <div className="admin-training__modal-edite-image-slider-wrap">
                <Typography variant="overline">{translations['zoom'][language]}</Typography>
                <Slider
                  value={zoom}
                  min={0.1}
                  max={3}
                  step={0.1}
                  aria-labelledby="Zoom"
                  onChange={(e, zoom) => setZoom(zoom)}
                  className="admin-training__modal-edite-slider"
                />
              </div>
              <div className="admin-training__modal-edite-image-slider-wrap">
                <Typography variant="overline">{translations['rotation'][language]}</Typography>
                <Slider
                  value={rotation}
                  min={0}
                  max={360}
                  step={1}
                  aria-labelledby="Rotation"
                  onChange={(e, rotation) => setRotation(rotation)}
                  className="admin-training__modal-edite-slider"
                />
              </div>
            </div>
          </div>
        </ModalWindow>
      )}
      {isModalNewPassword && (
        <ModalWindow
          title={translations['changePassword'][language]}
          handleClick={handleIsModalNewPassword}
        >
          <div className="admin-profile__modal-input-wrap">
            <PasswordInput
              password={existingPassword}
              setPassword={setExistingPassword}
              handleKeyEnter={() => handleIsModalNewPassword(true)}
              errorPassword={isErrorPassword ? translations['errorPassword1'][language] : ''}
            />
          </div>
        </ModalWindow>
      )}
      {isModalCreatePassword && (
        <ModalWindow
          title={translations['changePassword'][language]}
          handleClick={handleIsModalCreatePassword}
          rightBtn={translations['save'][language]}
        >
          <div className="admin-profile__modal-input-wrap">
            <PasswordInput
              password={newPassword}
              setPassword={setNewPassword}
              label={translations['newPassword'][language]}
              errorPassword={isErrorPassword ? (!newPassword?.length ? translations['enterPassword'][language] : newPassword === existingPassword ? translations['errorPassword2'][language] : !validatePassword(newPassword) ? translations['errorPassword'][language] : '') : ''}
            />
            <PasswordInput
              password={confirmPassword}
              setPassword={setConfirmPassword}
              label={translations['confirmNewPassword'][language]}
              handleKeyEnter={() => handleIsModalCreatePassword(true)}
              errorPassword={isErrorConfirmPassword ? translations['passwordsNotMatch'][language] : ''}
            />
          </div>
        </ModalWindow>
      )}
      {isModalNewEmail && (
        <ModalWindow
          title={translations['changeEmail'][language]}
          handleClick={handleIsModalNewEmail}
          rightBtn={translations['send'][language]}
          isRightBtnPreloader={isPreloader}
        >
          <div className="admin-profile__modal-input-wrap">
            <TextInput
              setValue={setEmail}
              value={email}
              label={translations['currentEmail'][language]}
              newStyle={{ maxWidth: '100%' }}
              isDisabled={true}
            />
            <TextInput
              setValue={setNewEmail}
              value={newEmail}
              label={translations['newEmail'][language]}
              newStyle={{ maxWidth: '100%' }}
              helperText={isErrorNewEmail ? (!email?.length ? translations['errorEmail1'][language] : !!email?.length && !validEmail(email) ? translations['errorEmail'][language] : translations['errorEmail2'][language]) : ''}
            />
          </div>
        </ModalWindow>
      )}
      <div className="admin-profile">
        {windowInnerWidth < 640 ? (
          <div className="admin-profile__mobile--wrap">
            <div className="admin-profile__info-tab-wrap">
              <button className="admin-profile__info-tab admin-profile__info-tab--active">{translations['info'][language]}</button>
              <NavLink
                className="admin-profile__info-tab"
                to={`/auth/${coachId}/billing`}
              >
                {translations['billing'][language]}
              </NavLink>
            </div>
            <div className="admin-profile__mobile-wrap">
              <div className="admin-profile__mobile-img--wrap">
                <div className="admin-profile__mobile-img-wrap">
                  <div className="admin-profile__img-wrap">
                    {newImage.name?.length ? (
                      <img
                        className="admin-profile__input-img"
                        src={URL.createObjectURL(newImage)}
                        alt="img"
                      />
                    ) : (
                      <>
                        <img
                          className="admin-profile__mobile-img"
                          src={image?.length ? image : defaultAvatar}
                          alt="img"
                        />
                        {!image?.length && (
                          <div className="admin-profile__img-text">
                            {coach.first_name.slice(0, 1)}
                            {coach.last_name.slice(0, 1)}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  <button
                    className="admin-profile__mobile-img-btn-wrap"
                    onClick={(e) => setAnchorImageEl(e.currentTarget)}
                    disabled={isUploadImg}
                  >
                    <img
                      className="admin-profile__mobile-img-btn"
                      src={editBgWhite}
                      alt="img"
                    />
                  </button>
                  <Popover
                    id={'admin-profile__popover-edite-image'}
                    open={Boolean(anchorImageEl)}
                    anchorEl={anchorImageEl}
                    onClose={() => setAnchorImageEl(null)}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 20,
                    }}
                    classes={{
                      root: 'upload-image__popover-wrap',
                    }}
                  >
                    <div className="upload-image__popover">
                      <button
                        className={`upload-image__popover-btn ${!image?.length ? 'disabledContainer' : ''}`}
                        onClick={handleClosePopover}
                      >
                        {translations['editImage'][language]}
                      </button>
                      <label
                        className="upload-image__popover-label"
                        htmlFor="creationtImgExercise"
                        onClick={() => setAnchorImageEl(null)}
                      >
                        {translations['uploadFromComputer'][language]}
                      </label>
                    </div>
                  </Popover>
                  <input
                    className="create-exercise__create-img-link-upload-input"
                    onChange={(e) => handleUploadImg(e.target.files)}
                    type="file"
                    accept="image/*"
                    id="creationtImgExercise"
                  />
                </div>
                <div className="admin-profile__mobile-input-wrap admin-profile__mobile-input-name-wrap">
                  <TextInput
                    setValue={setFirstName}
                    value={firstName}
                    label={translations['firstName'][language]}
                    newStyle={{ maxWidth: '100%' }}
                    helperText={isErrorFirstName ? translations['fieldRequired'][language] : ''}
                  />
                  <TextInput
                    setValue={setLastName}
                    value={lastName}
                    label={translations['lastName'][language]}
                    newStyle={{ maxWidth: '100%' }}
                    helperText={isErrorLastName ? translations['fieldRequired'][language] : ''}
                  />
                </div>
              </div>
              <div className="admin-profile__mobile-input-wrap">
                {coach?.business_type === COMPANY && (
                  <TextInput
                    setValue={setBusiness_name}
                    value={business_name}
                    label={translations['businessName'][language]}
                    helperText={isErrorBusiness_name && coach?.business_type === COMPANY ? translations['fieldRequired'][language] : ''}
                  />
                )}
                <div className={`admin-profile__input-password-wrap ${coach?.is_google_auth ? 'displayNone' : ''}`}>
                  <TextInput
                    setValue={setEmail}
                    value={newEmail?.length && isNewEmail ? newEmail : email}
                    label="Email"
                    newStyle={{ maxWidth: '100%' }}
                    isDisabled={true}
                  />
                  {isNewEmail && (
                    <button
                      className={`admin-profile__btn-resend ${isStartTimer ? 'admin-profile__btn-resend--disabled' : ''}`}
                      onClick={handleResendMail}
                    >
                      <span>{translations['resendMail'][language]}</span>
                      {isStartTimer && <CountdownTimer setIsStartTimer={setIsStartTimer} />}
                    </button>
                  )}
                  <button
                    className="admin-profile__btn-edite"
                    onClick={() => setIsModalNewEmail(true)}
                  >
                    <img
                      src={editViolet}
                      alt="edit"
                    />
                  </button>
                </div>
                <div className={`admin-profile__input-password-wrap ${coach?.is_google_auth ? 'displayNone' : ''}`}>
                  <TextInput
                    setValue={setPassword}
                    value={'********'}
                    label={translations['password'][language]}
                    isDisabled={true}
                  />
                  <button
                    className={`admin-profile__btn-edite`}
                    onClick={() => setIsModalNewPassword(true)}
                  >
                    <img
                      src={editViolet}
                      alt="edit"
                    />
                  </button>
                </div>
                <div className="sign-up__form-phone-wrap">
                  <PhoneInput
                    className={`sign-up__form-phone ${phone?.length > 4 ? 'sign-up__form-phone--is-active' : ''}`}
                    country={'ua'}
                    value={!phone?.length ? '380' : phone}
                    onChange={handlePhoneChange}
                    onFocus={() => handleFocusAppInput(true, (res) => dispatch(setIsInputFocus(res)), is_app)}
                    onBlur={() => handleFocusAppInput(false, (res) => dispatch(setIsInputFocus(res)), is_app)}
                  />
                  {isErrorPhone && <div className="sign-up__form-phone-error">{translations['errorPhone'][language]}</div>}
                </div>
                <TextInput
                  setValue={setUserLocation}
                  value={userLocation}
                  label={`${translations['location'][language]} (${translations['optional'][language]})`}
                />
                <div className="admin-profile__mobile-save-btn-wrap">
                  <button
                    className="mainBtnDark"
                    onClick={handleSave}
                  >
                    {translations['save'][language]}
                  </button>
                </div>
                <div className="admin-profile__mobile-save-btn-wrap admin-profile__mobile-save-btn-wrap--margin">
                  <button
                    className="mainBtnWhite"
                    onClick={() => setIsModalDeleteAccount(true)}
                    disabled={isBtnLoader}
                  >
                    {
                      isBtnLoader &&
                        <span className="coaching-day-view__btn-loader user-profile__btn-loader"></span>
                    }
                    <span>{translations['deleteAccount'][language]}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="admin-profile__input-wrap">
              <div className="admin-profile__input-img-wrap">
                <div className="admin-profile__input-img--wrap">
                  <div className="admin-profile__img-wrap">
                    {newImage.name?.length ? (
                      <img
                        className="admin-profile__input-img"
                        src={URL.createObjectURL(newImage)}
                        alt="img"
                      />
                    ) : (
                      <>
                        <img
                          className="admin-profile__input-img"
                          src={image?.length ? image : defaultAvatar}
                          alt="img"
                        />
                        {!image?.length && (
                          <div className="admin-profile__img-text">
                            {coach.first_name.slice(0, 1)}
                            {coach.last_name.slice(0, 1)}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  <button
                    className="admin-profile__mobile-img-btn-wrap"
                    onClick={(e) => setAnchorImageEl(e.currentTarget)}
                    disabled={isUploadImg}
                  >
                    <img
                      className="admin-profile__mobile-img-btn"
                      src={editBgWhite}
                      alt="img"
                    />
                  </button>
                  <Popover
                    id={'admin-profile__popover-edite-image'}
                    open={Boolean(anchorImageEl)}
                    anchorEl={anchorImageEl}
                    onClose={() => setAnchorImageEl(null)}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 20,
                    }}
                    classes={{
                      root: 'upload-image__popover-wrap',
                    }}
                  >
                    <div className="upload-image__popover">
                      <button
                        className={`upload-image__popover-btn ${!image?.length ? 'disabledContainer' : ''}`}
                        onClick={handleClosePopover}
                      >
                        {translations['editImage'][language]}
                      </button>
                      <label
                        className="upload-image__popover-label"
                        htmlFor="creationtImgExercise"
                        onClick={() => setAnchorImageEl(null)}
                      >
                        {translations['uploadFromComputer'][language]}
                      </label>
                    </div>
                  </Popover>
                  <input
                    className="create-exercise__create-img-link-upload-input"
                    onChange={(e) => handleUploadImg(e.target.files)}
                    type="file"
                    accept="image/*"
                    id="creationtImgExercise"
                  />
                </div>
                <div className="admin-profile__input-name-wrap">
                  <TextInput
                    setValue={setFirstName}
                    value={firstName}
                    label={translations['firstName'][language]}
                    newStyle={{ maxWidth: '100%' }}
                    helperText={isErrorFirstName ? translations['fieldRequired'][language] : ''}
                  />
                  <TextInput
                    setValue={setLastName}
                    value={lastName}
                    label={translations['lastName'][language]}
                    newStyle={{ maxWidth: '100%' }}
                    helperText={isErrorLastName ? translations['fieldRequired'][language] : ''}
                  />
                </div>
              </div>
              {coach?.business_type === COMPANY && (
                <TextInput
                  setValue={setBusiness_name}
                  value={business_name}
                  label={translations['businessName'][language]}
                  helperText={isErrorBusiness_name && coach?.business_type === COMPANY ? translations['fieldRequired'][language] : ''}
                />
              )}
              <div className={`admin-profile__input-password-wrap ${coach?.is_google_auth ? 'displayNone' : ''}`}>
                <TextInput
                  setValue={setEmail}
                  value={newEmail?.length && isNewEmail ? newEmail : email}
                  label="Email"
                  newStyle={{ maxWidth: '100%' }}
                  isDisabled={true}
                />
                {isNewEmail && (
                  <button
                    className={`admin-profile__btn-resend ${isStartTimer ? 'disabledContainer' : ''}`}
                    onClick={handleResendMail}
                  >
                    <span>{translations['resendMail'][language]}</span>
                    {isStartTimer && <CountdownTimer setIsStartTimer={setIsStartTimer} />}
                  </button>
                )}
                <button
                  className="admin-profile__btn-edite"
                  onClick={() => setIsModalNewEmail(true)}
                >
                  <img
                    src={editViolet}
                    alt="edit"
                  />
                </button>
              </div>
              <div className={`admin-profile__input-password-wrap ${coach?.is_google_auth ? 'displayNone' : ''}`}>
                <TextInput
                  setValue={setPassword}
                  value={'********'}
                  label={translations['password'][language]}
                  isDisabled={true}
                />
                <button
                  className={`admin-profile__btn-edite`}
                  onClick={() => setIsModalNewPassword(true)}
                >
                  <img
                    src={editViolet}
                    alt="edit"
                  />
                </button>
              </div>
              <div className="sign-up__form-phone-wrap">
                <PhoneInput
                  className={`sign-up__form-phone ${phone?.length > 4 ? 'sign-up__form-phone--is-active' : ''}`}
                  country={'ua'}
                  value={!phone?.length ? '380' : phone}
                  onChange={handlePhoneChange}
                  onFocus={() => handleFocusAppInput(true, (res) => dispatch(setIsInputFocus(res)), is_app)}
                  onBlur={() => handleFocusAppInput(false, (res) => dispatch(setIsInputFocus(res)), is_app)}
                />
                {isErrorPhone && <div className="sign-up__form-phone-error">{translations['errorPhone'][language]}</div>}
              </div>
              <TextInput
                setValue={setUserLocation}
                value={userLocation}
                label={`${translations['location'][language]} (${translations['optional'][language]})`}
              />
            </div>

            <div className="admin-profile__add-btn-wrap">
              <button
                className="mainBtnWhite"
                onClick={() => setIsModalDeleteAccount(true)}
                disabled={isBtnLoader}
              >
                {
                  isBtnLoader &&
                    <span className="coaching-day-view__btn-loader user-profile__btn-loader"></span>
                }
                <span>{translations['deleteAccount'][language]}</span>
              </button>
              <button
                className="mainBtnDark"
                onClick={handleSave}
              >
                {translations['save'][language]}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default AdminProfileView;
